import { ApiImageClient } from '@/api';

async function apiUserGetCompany({ id }: { id: string }) {
  try {
    const result = await ApiImageClient.get(`/companies/${id}`).then(({ data }) => data);
    return result;
  } catch (e) {
    console.error('apiUserGetCompany_Error', e);
  }
}

async function apiUserGetCompanies(token: string) {
  try {
    const result = await ApiImageClient.get(`/companies/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(({ data }) => data);

    return result;
  } catch (e) {
    console.error('apiUserGetCompanies_Error', e);
  }
}

async function apiUserPostCompany({ id, data }: { id: string; data: any }) {
  try {
    const result = await ApiImageClient.put(`/companies/${id}`, { data }).then(({ data }) => data);
    return result;
  } catch (e) {
    console.error('apiUserPostCompany_Error', e);
  }
}

export { apiUserGetCompany, apiUserGetCompanies, apiUserPostCompany };

import { Avatar, Button, Table, TextInput } from '@mantine/core';
import { IconLayoutGridFilled, IconList } from '@tabler/icons-react';
import { useEffect, useState } from 'react';

import DepartmentCard from '@/components/DepartmentCard';
import { apiUserGetDepartments } from '@/api';
import tempData from '../../departmentTempData';
import { useNavigate } from 'react-router-dom';

function DepartmentManagement() {
  // list or grid
  const [layout, setLayout] = useState('list');
  const [departmentData, setDepartmentData] = useState([]);
  const navigate = useNavigate();

  const handleAddEmployee = () => {
    navigate('/hrMainPage/addDepartment');
  };

  // 暫時先拿掉
  // const handleDisplayMember = (members) => {
  //   if (members.length) {
  //     return (
  //       <div className="flex">
  //         <Avatar.Group>
  //           {members.slice(0, 3).map((member) => {
  //             return member?.img ? (
  //               <Avatar src={member?.img} />
  //             ) : (
  //               <Avatar key={member?.name} name={member?.name} />
  //             );
  //           })}
  //         </Avatar.Group>
  //         <div className="ml-2 flex items-center">{`${members[0]?.name}等${members.length}人已經加入`}</div>
  //       </div>
  //     );
  //   } else {
  //     return <div style={{ color: '#6F6F6F' }}>尚未有人加入此部門</div>;
  //   }
  // };

  const handleLayoutChange = (layout) => {
    setLayout(layout);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let result = await apiUserGetDepartments();
        setDepartmentData(result?.data);
      } catch (error) {
        console.error('Error fetching department data:', error);
      }
    };

    fetchData();
  }, []);

  return departmentData?.length === 0 ? (
    <div className="flex h-full items-center justify-center">
      <div className="w-[487px] rounded-xl bg-white px-16 py-20">
        <div className="text-center" style={{ color: '#6F6F6F' }}>
          您尚未新增部門資料！你可以試試：
        </div>
        <div className="flex text-center">
          <Button
            variant="outline"
            color="gray"
            style={{ width: '100%', margin: '1rem' }}
            radius="md"
          >
            快速建立
          </Button>
          <Button
            variant="outline"
            color="gray"
            style={{ width: '100%', margin: '1rem' }}
            radius="md"
            onClick={handleAddEmployee}
          >
            手動新增
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div
        className="flex items-center justify-between px-4"
        style={{ backgroundColor: '#ECECEC' }}
      >
        <div className="flex flex-1 justify-center">
          <TextInput />
        </div>
        <Button
          variant="default"
          style={{ minWidth: '3rem', margin: '1rem' }}
          radius="xl"
          onClick={handleAddEmployee}
        >
          新增部門
        </Button>
      </div>
      <div className="flex justify-end px-8">
        <IconList
          onClick={() => handleLayoutChange('list')}
          className="mr-2 cursor-pointer"
          size={30}
        />
        <IconLayoutGridFilled
          onClick={() => handleLayoutChange('grid')}
          className="mr-2 cursor-pointer"
          size={30}
        />
      </div>
      {layout === 'list' ? (
        <div className="p-4">
          <Table highlightOnHover>
            <Table.Thead>
              <Table.Tr style={{ color: '#6F6F6F' }}>
                <Table.Th>代號</Table.Th>
                <Table.Th>部門</Table.Th>
                {/* 部門成員先拿掉
                <Table.Th>成員</Table.Th> */}
                <Table.Th>部門描述</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody style={{ cursor: 'pointer' }}>
              {departmentData.map((element, index) => {
                const {
                  code = '',
                  name = '',
                  workContent = '',
                  company = '',
                  leaders = '',
                } = element?.attributes;

                return (
                  <Table.Tr
                    key={code}
                    onClick={() => navigate(`/hrMainPage/department/${element.id}`)}
                  >
                    <Table.Td>{code}</Table.Td>
                    <Table.Td>{name}</Table.Td>
                    {/* 部門成員先拿掉
                  <Table.Td>{handleDisplayMember(element.members)}</Table.Td> */}
                    <Table.Td>{workContent}</Table.Td>
                  </Table.Tr>
                );
              })}
            </Table.Tbody>
          </Table>
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          {departmentData.map((data) => {
            return <DepartmentCard props={data} />;
          })}
        </div>
      )}
    </>
  );
}

export default DepartmentManagement;

import { Anchor, Box, Button, Checkbox, Divider, Group, Select, Textarea } from '@mantine/core';
import { IconMail, IconPencil } from '@tabler/icons-react';
import { useEffect, useState } from 'react';

import { Input } from '@mantine/core';
import { apiUserGetCompany } from '@/api';
import { useData } from '@/utility/state/contextAPI';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

function CompanyInfoPage() {
  const [companyData, setCompanyData] = useState(null);

  const navigate = useNavigate();
  const {
    auth: { jwtToken },
  } = useData();

  const {
    partners = '',
    website = '',
    company = '',
    users_permissions_users = {},
    businessIdNumber = '',
    companyRepresentative = '',
    business = '',
    capitalAmount = '',
    email = '',
    localPhoneNumber = '',
    fax = '',
    contact = '',
    telephone = '',
    department = '',
    city = '',
    district = '',
    postalCode = '',
    address = '',
    companyAbbreviation = '',
    invoice = '',
    invitationCode = '',
    logo = '',
    employees = '',
  } = companyData?.data?.attributes || {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        let id = localStorage.getItem('companyId');
        let result = await apiUserGetCompany({ id });
        setCompanyData(result);
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex h-full items-center justify-center p-14">
      <div className="w-[700px] rounded-xl bg-white px-16">
        <div className="flex w-full justify-between py-8">
          <div className="text-6xl font-bold" style={{ color: '#6F6F6F' }}>
            {company}
          </div>
          <div className="flex">
            <IconPencil
              className="cursor-pointer"
              size={32}
              onClick={() => navigate(`/hrMainPage/editCompanyInfo/`)}
            />
          </div>
        </div>
        <div style={{ color: '#6F6F6F' }} className="py-2">
          建立公司資訊，節省您在管理時所花費的時間。建立只需兩分鐘。
        </div>
        <div className="text-2xl font-bold" style={{ color: '#6F6F6F' }}>
          基本資訊
        </div>
        <div className="py-2 flex">
          <div>
            <div style={{ color: '#6F6F6F' }}>公司名稱</div>
            <div className="flex py-2 content-center">
              <IconMail size={16} />
              <div className="px-4">{company}</div>
            </div>
          </div>
          <div className="pl-32">
            <div style={{ color: '#6F6F6F' }}>統一編號</div>
            <div className="flex py-2 content-center">
              <IconMail size={16} />
              <div className="px-4">{businessIdNumber}</div>
            </div>
          </div>
        </div>
        <div className="py-2 flex">
          <div className="flex items-center justify-center">
            <div className="py-2">
              <div style={{ color: '#6F6F6F' }}>負責人名稱</div>
              <div className="flex py-2 content-center">
                <IconMail size={16} />
                <div className="px-4">{companyRepresentative}</div>
              </div>
            </div>

            <div className="pl-32">
              <div style={{ color: '#6F6F6F' }}>營業項目</div>
              <div className="flex py-2 content-center">
                <IconMail size={16} />
                <div className="px-4">{business}</div>
              </div>
            </div>
            <div className="pl-32">
              <div style={{ color: '#6F6F6F' }}>資本額</div>
              <div className="flex py-2 content-center">
                <IconMail size={16} />
                <div className="px-4">{capitalAmount}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-2xl font-bold" style={{ color: '#6F6F6F' }}>
          聯絡資訊
        </div>
        <div className="flex items-center justify-start">
          <div className="py-4">
            <div style={{ color: '#6F6F6F' }}>Email</div>
            <div className="flex py-2 align-center">
              <IconMail size={16} />
              <div className="px-4">{email}</div>
            </div>
          </div>
          <div className="pl-32">
            <div style={{ color: '#6F6F6F' }}>市內電話</div>
            <div className="flex py-2 align-center">
              <IconMail size={16} />
              <div className="px-4">{invitationCode}</div>
            </div>
          </div>
          <div className="pl-32">
            <div style={{ color: '#6F6F6F' }}>傳真</div>
            <div className="flex py-2 align-center">
              <IconMail size={16} />
              <div className="px-4">{fax}</div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-start">
          <div className="py-4">
            <div style={{ color: '#6F6F6F' }}>聯絡人</div>
            <div className="flex py-2 align-center">
              <IconMail size={16} />
              <div className="px-4">{contact}</div>
            </div>
          </div>

          <div className="pl-32">
            <div style={{ color: '#6F6F6F' }}>電話</div>
            <div className="flex py-2 align-center">
              <IconMail size={16} />
              <div className="px-4">{telephone}</div>
            </div>
          </div>
          <div className="pl-32">
            <div style={{ color: '#6F6F6F' }}>部門</div>
            <div className="flex py-2 align-center">
              <IconMail size={16} />
              <div className="px-4">{department}</div>
            </div>
          </div>
        </div>

        <div className="text-2xl font-bold" style={{ color: '#6F6F6F' }}>
          聯絡地址
        </div>
        <div className="flex items-center justify-center">
          <div className="py-4">
            <div style={{ color: '#6F6F6F' }}>縣市</div>
            <div className="flex py-2 align-center">
              <IconMail size={16} />
              <div className="px-4">{city}</div>
            </div>
          </div>

          <div className="pl-32">
            <div style={{ color: '#6F6F6F' }}>行政區</div>
            <div className="flex py-2 align-center">
              <IconMail size={16} />
              <div className="px-4">{district}</div>
            </div>
          </div>
          <div className="pl-32">
            <div style={{ color: '#6F6F6F' }}>郵遞區號</div>
            <div className="flex py-2 align-center">
              <IconMail size={16} />
              <div className="px-4">{postalCode}</div>
            </div>
          </div>
          <div className="pl-32">
            <div style={{ color: '#6F6F6F' }}>地址</div>
            <div className="flex py-2 align-center">
              <IconMail size={16} />
              <div className="px-4">{address}</div>
            </div>
          </div>
        </div>

        <div className="text-2xl font-bold" style={{ color: '#6F6F6F' }}>
          進階設定
        </div>

        <div className="py-4">
          <div style={{ color: '#6F6F6F' }}>Logo</div>
          <div className="flex items-center justify-start">
            <div className="flex py-2 align-center">
              <IconMail size={16} />
              <div className="px-4">{logo}</div>
            </div>
          </div>
        </div>

        <div className="py-4">
          <div style={{ color: '#6F6F6F' }}>網站</div>
          <div className="flex py-2 align-center">
            <IconMail size={16} />
            <div className="px-4">{website}</div>
          </div>
        </div>
        <div className="flex items-center justify-start">
          <div className="py-4">
            <div style={{ color: '#6F6F6F' }}>公司簡稱</div>
            <div className="flex py-2 align-center">
              <IconMail size={16} />
              <div className="px-4">{companyAbbreviation}</div>
            </div>
          </div>
          <div className="pl-32">
            <div style={{ color: '#6F6F6F' }}>發票抬頭</div>
            <div className="flex py-2 align-center">
              <IconMail size={16} />
              <div className="px-4">{invoice}</div>
            </div>
          </div>
        </div>

        <div className="text-2xl font-bold" style={{ color: '#6F6F6F' }}>
          邀請碼
        </div>

        <div className="py-4 pl-2">
          <div style={{ color: '#6F6F6F' }}>輸入邀請碼</div>
          <div className="flex py-2 align-center">
            <IconMail size={16} />
            <div className="px-4">{invitationCode}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyInfoPage;

import { Anchor, Box, Button, Checkbox, Input, Textarea } from '@mantine/core';
import { Badge, Select } from 'antd';
import { IconMail, IconSearch, IconUser } from '@tabler/icons-react';

import Card from '@/components/Card';
import { apiUserPostPartner } from '@/api';
import { useData } from '@/utility/state/contextAPI';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

function AddCoopListPage() {
  const [currentStage, setCurrentStage] = useState(0);
  const [coopData, setCoopData] = useState({
    name: '',
    abbreviation: '',
    serialNumber: '',
    businessIdNumber: '',
    businessRegistrationAddress: '',
    invoiceAddress: '',
    shippingAddress: '',
    contactPerson: '',
    invoiceType: '',
    email: '',
    telephoneOrFax: '',
    businessItems: '',
    company: '',
  });
  const {
    modal: { contextOpenModal, contextCloseModal, contextSetModalContent },
  } = useData();
  const navigate = useNavigate();

  const handleSubmit = async (coopData) => {
    try {
      const result = await apiUserPostPartner(coopData);
      console.log('result_handleSubmit', result);
      if (result) {
        setCurrentStage(1);
      }
    } catch (error) {
      console.error('apiUserPostPartner', error);
      return;
    }
  };

  const handleDataChange = (name, value) => {
    setCoopData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const {
    name,
    abbreviation,
    serialNumber,
    businessIdNumber,
    businessRegistrationAddress,
    invoiceAddress,
    shippingAddress,
    contactPerson,
    invoiceType,
    email,
    telephoneOrFax,
    businessItems,
    company,
  } = coopData;

  return currentStage === 0 ? (
    <div className="flex h-full items-center justify-center p-8">
      <div className="w-[765px] rounded-xl bg-white p-14">
        <>
          <div className="py-3 text-6xl font-bold" style={{ color: '#6F6F6F' }}>
            新增合作方
          </div>

          <div className="py-4">
            <div className="py-3 text-2xl font-bold" style={{ color: '#6F6F6F' }}>
              基本資訊
            </div>
            <div className="flex items-center justify-start">
              <div className="py-4">
                <div style={{ color: '#6F6F6F' }}>公司名</div>
                <Input
                  value={name}
                  onChange={({ target: { value } }) => handleDataChange('name', value)}
                  placeholder="Input component"
                  leftSection={<IconMail size={16} />}
                />
              </div>

              <div className="py-4 pl-2">
                <div style={{ color: '#6F6F6F' }}>簡稱</div>
                <Input
                  value={abbreviation}
                  onChange={({ target: { value } }) => handleDataChange('abbreviation', value)}
                  placeholder="Input component"
                  leftSection={<IconMail size={16} />}
                />
              </div>
              <div className="py-4 pl-2">
                <div style={{ color: '#6F6F6F' }}>統編</div>
                <Input
                  value={businessIdNumber}
                  onChange={({ target: { value } }) => handleDataChange('businessIdNumber', value)}
                  placeholder="Input component"
                  leftSection={<IconMail size={16} />}
                />
              </div>
            </div>
            <div className="py-4">
              <div style={{ color: '#6F6F6F' }}>廣達組織 ID</div>
              <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
            </div>
          </div>
          <div className="py-4">
            <div className="py-3 text-2xl font-bold" style={{ color: '#6F6F6F' }}>
              聯絡地址
            </div>
            <div className="py-2 text-base font-bold" style={{ color: '#6F6F6F' }}>
              1. 營業登記地址
            </div>
            <div className="flex items-center justify-start">
              <div className="py-2">
                <div style={{ color: '#6F6F6F' }}>縣市</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>

              <div className=" pl-2">
                <div style={{ color: '#6F6F6F' }}>行政區</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>
              <div className=" pl-2">
                <div style={{ color: '#6F6F6F' }}>郵遞區號</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>
              <div className=" pl-2">
                <div style={{ color: '#6F6F6F' }}>地址</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>
            </div>

            <div className="pt-8 text-base font-bold" style={{ color: '#6F6F6F' }}>
              2. 發票地址
            </div>
            <div className="flex items-center justify-start">
              <div className="py-2">
                <div style={{ color: '#6F6F6F' }}>縣市</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>

              <div className=" pl-2">
                <div style={{ color: '#6F6F6F' }}>行政區</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>
              <div className=" pl-2">
                <div style={{ color: '#6F6F6F' }}>郵遞區號</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>
              <div className=" pl-2">
                <div style={{ color: '#6F6F6F' }}>地址</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>
            </div>
            <Checkbox label="同營業登記地址" color="gray" />

            <div className="pt-8 text-base font-bold" style={{ color: '#6F6F6F' }}>
              1. 送貨地址
            </div>
            <div className="flex items-center justify-start">
              <div className="py-2">
                <div style={{ color: '#6F6F6F' }}>縣市</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>

              <div className="pl-2">
                <div style={{ color: '#6F6F6F' }}>行政區</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>
              <div className="pl-2">
                <div style={{ color: '#6F6F6F' }}>郵遞區號</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>
              <div className="pl-2">
                <div style={{ color: '#6F6F6F' }}>地址</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>
            </div>
            <Checkbox label="同營業登記地址" color="gray" />
          </div>

          <div className="py-4">
            <div className="py-3 text-2xl font-bold" style={{ color: '#6F6F6F' }}>
              聯絡資訊
            </div>
            <div className="py-2 text-base font-bold" style={{ color: '#6F6F6F' }}>
              1. 基本聯絡資訊
            </div>
            <div className="flex items-center justify-start">
              <div className="py-2">
                <div style={{ color: '#6F6F6F' }}>電話</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>

              <div className=" pl-2">
                <div style={{ color: '#6F6F6F' }}>傳真</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>
            </div>

            <div className="py-2 text-base font-bold" style={{ color: '#6F6F6F' }}>
              2. 聯絡人資訊
            </div>
            <div className="flex items-center justify-start">
              <div className="py-2">
                <div style={{ color: '#6F6F6F' }}>聯絡人</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>

              <div className="pl-2">
                <div style={{ color: '#6F6F6F' }}>部門</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>

              <div className="pl-2">
                <div style={{ color: '#6F6F6F' }}>電話</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>
            </div>
            <div className="flex items-center justify-start">
              <div className="py-2">
                <div style={{ color: '#6F6F6F' }}>E-mail / 帳號</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>

              <div className="pl-2">
                <div style={{ color: '#6F6F6F' }}>發票種類</div>
                <Input
                  placeholder="Input component"
                  leftSection={
                    <IconMail
                      size={16}
                      value={invoiceType}
                      onChange={({ target: { value } }) => handleDataChange('invoiceType', value)}
                    />
                  }
                />
              </div>
            </div>
          </div>

          <div className="py-4">
            <div className="py-3 text-2xl font-bold" style={{ color: '#6F6F6F' }}>
              營業項目
            </div>
            <Textarea
              value={businessItems}
              onChange={({ target: { value } }) => handleDataChange('businessItems', value)}
            />
          </div>

          <Button
            variant="filled"
            color="gray"
            style={{ width: '100%', margin: '1rem' }}
            radius="md"
            onClick={() => handleSubmit(coopData)}
          >
            新增
          </Button>
        </>
      </div>
    </div>
  ) : (
    <div className="flex h-full items-center justify-center p-14 text-center">
      <div className="w-[487px] rounded-xl bg-white p-8">
        <div style={{ color: '#6F6F6F' }}>建立合作方成功！</div>
        <Button
          variant="filled"
          color="gray"
          style={{ width: '100%', margin: '1rem 0' }}
          radius="md"
          onClick={() => navigate('/hrMainPage/coop')}
        >
          確定
        </Button>
      </div>
    </div>
  );
}
export default AddCoopListPage;

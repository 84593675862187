import { useEffect, useState } from 'react';

import { Button } from '@mantine/core';
import Card from '@/components/Card';
import { IconPencil } from '@tabler/icons-react';
import { apiUserGetDepartment } from '@/api';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

function DepartmentPage() {
  const [departmentData, setDepartmentData] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  const { code, workContent, name, company, leaders, members } = departmentData || {};

  const handleProcessPersonData = (data) => {
    if (!data) return;

    return data.data.map((person) => {
      const { name, email, birthday } = person.attributes;
      const image = person.attributes?.image || ''; // Assuming image might be missing
      return { name, email, birthday, image };
    });
  };

  const handleDisplayMembers = (members) => {
    if (!members?.length) {
      return '';
    }

    return members.map((member) => (
      <Card customStyle={{ baseStyle: { margin: '0.5rem' } }} props={member} />
    ));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let result = await apiUserGetDepartment({ id });
        setDepartmentData(result?.data?.attributes);
      } catch (error) {
        console.error('Error fetching department data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex h-full items-center justify-center p-8">
      <div className="w-[765px] rounded-xl bg-white p-14">
        <>
          <div className="flex justify-end">
            <IconPencil
              className="cursor-pointer"
              size={32}
              onClick={() => navigate(`/hrMainPage/editDepartment/${id}`)}
            />
          </div>
          <div className="flex">
            <div>
              <div className="py-3 text-2xl font-bold" style={{ color: '#6F6F6F' }}>
                部門名稱
              </div>
              <div>{name}</div>
            </div>
            <div className="pl-3">
              <div className="py-3 text-2xl font-bold" style={{ color: '#6F6F6F' }}>
                部門代號
              </div>
              <div>{code}</div>
            </div>
          </div>

          <div className="py-4">
            <div className="py-3 text-2xl font-bold" style={{ color: '#6F6F6F' }}>
              部門描述
            </div>
            <div>{workContent}</div>
          </div>

          <div>
            <div className="text-2xl font-bold" style={{ color: '#6F6F6F' }}>
              部門主管
            </div>
            <div className="grid grid-cols-1 gap-1 md:grid-cols-3">
              {handleDisplayMembers(handleProcessPersonData(leaders))}
            </div>
          </div>
          <div>
            <div className="text-2xl font-bold" style={{ color: '#6F6F6F' }}>
              部門成員
            </div>
            <div className="grid grid-cols-1 gap-1 md:grid-cols-3">
              {handleDisplayMembers(handleProcessPersonData(members))}
            </div>
          </div>
          <div className="flex">
            <Button
              variant="filled"
              color="gray"
              style={{ width: '100%', margin: '1rem' }}
              radius="md"
            >
              刪除部門
            </Button>
          </div>
        </>
      </div>
    </div>
  );
}

export default DepartmentPage;

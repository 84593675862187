import { Avatar, Button, Table, TextInput } from '@mantine/core';
import { IconLayoutGridFilled, IconList } from '@tabler/icons-react';
import { useEffect, useState } from 'react';

import { apiUserGetPartners } from '@/api';
import { useNavigate } from 'react-router-dom';

function CoopManagement() {
  // list or grid
  const [layout, setLayout] = useState('list');
  const [coopData, setCoopData] = useState([]);
  const navigate = useNavigate();

  const handleAddCoop = () => {
    navigate('/hrMainPage/addCoop');
  };

  // const handleDisplayMember = (members) => {
  //   if (members.length) {
  //     return (
  //       <div className="flex">
  //         <Avatar.Group>
  //           {members.slice(0, 3).map((member) => {
  //             return member?.img ? (
  //               <Avatar src={member?.img} />
  //             ) : (
  //               <Avatar key={member?.name} name={member?.name} />
  //             );
  //           })}
  //         </Avatar.Group>
  //         <div className="ml-2 flex items-center">{`${members[0]?.name}等${members.length}人已經加入`}</div>
  //       </div>
  //     );
  //   } else {
  //     return <div style={{ color: '#6F6F6F' }}>尚未有人加入此部門</div>;
  //   }
  // };

  const handleLayoutChange = (layout) => {
    setLayout(layout);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let result = await apiUserGetPartners();
        setCoopData(result?.data);
      } catch (error) {
        console.error('Error fetching department data:', error);
      }
    };

    fetchData();
  }, []);

  return coopData?.length === 0 ? (
    <div className="flex h-full items-center justify-center">
      <div className="w-[487px] rounded-xl bg-white px-16 py-20">
        <div className="text-center" style={{ color: '#6F6F6F' }}>
          您尚未新增合作方資料！你可以試試：
        </div>
        <div className="flex text-center">
          <Button
            variant="outline"
            color="gray"
            style={{ width: '100%', margin: '1rem' }}
            radius="md"
            onClick={handleAddCoop}
          >
            手動新增
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div
        className="flex items-center justify-between px-4"
        style={{ backgroundColor: '#ECECEC' }}
      >
        <div className="flex flex-1 justify-center">
          <TextInput />
        </div>
        <Button
          variant="default"
          style={{ minWidth: '3rem', margin: '1rem' }}
          radius="xl"
          onClick={handleAddCoop}
        >
          新增合作方
        </Button>
      </div>
      <div className="flex justify-end px-8">
        <IconList
          onClick={() => handleLayoutChange('list')}
          className="mr-2 cursor-pointer"
          size={30}
        />
        <IconLayoutGridFilled
          onClick={() => handleLayoutChange('grid')}
          className="mr-2 cursor-pointer"
          size={30}
        />
      </div>
      {layout === 'list' ? (
        <div className="p-4">
          <Table highlightOnHover>
            <Table.Thead>
              <Table.Tr style={{ color: '#6F6F6F' }}>
                <Table.Th>公司名</Table.Th>
                <Table.Th>統編</Table.Th>
                <Table.Th>聯絡人</Table.Th>
                <Table.Th>電話</Table.Th>
                <Table.Th>E-mail</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody style={{ cursor: 'pointer' }}>
              {/* {tempData.map((element, index) => (
                <Table.Tr
                  key={element.no}
                  onClick={() => navigate(`/hrMainPage/department/${element.no}`)}
                >
                  <Table.Td>{element.no}</Table.Td>
                  <Table.Td>{element.department}</Table.Td>
                  <Table.Td>{element.descriptions}</Table.Td>
                </Table.Tr>
              ))} */}
            </Table.Tbody>
          </Table>
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          {/* {tempData.map((data) => {
            return <DepartmentCard props={data} />;
          })} */}
        </div>
      )}
    </>
  );
}

export default CoopManagement;

import { ApiImageClient } from '@/api';

async function apiUserGetEmployee({ id }: { id: string }) {
  try {
    const result = await ApiImageClient.get(`/employee/${id}`).then(({ data }) => data);
    return result;
  } catch (e) {
    console.error('apiUserEmployee_Error', e);
  }
}

async function apiUserGetEmployees() {
  try {
    const result = await ApiImageClient.get(`/employees`).then(({ data }) => data);
    return result;
  } catch (e) {
    console.error('apiUserGetEmployees_Error', e);
  }
}

export { apiUserGetEmployee, apiUserGetEmployees };

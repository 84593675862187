import * as _ from 'lodash';

import { Button, Input, Textarea } from '@mantine/core';
import { IconMail, IconPencil, IconUser } from '@tabler/icons-react';
import { apiUserGetDepartment, apiUserPutDepartment } from '@/api';
import { useEffect, useState } from 'react';

import { Badge } from 'antd';
import Card from '@/components/Card';
import departmentTempData from 'departmentTempData';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

function EditDepartmentPage() {
  const { id } = useParams();
  const [departmentData, setDepartmentData] = useState([]);
  const navigate = useNavigate();

  const { code, workContent, name, company, leaders, members } = departmentData || {};

  const handleRemoveMember = (member) => {
    let newMemberData = departmentData.members?.data?.filter(
      (currMember) => currMember?.attributes?.name !== member.name,
    );
    setDepartmentData({
      ...departmentData,
      members: { data: newMemberData },
    });
  };

  const handleDisplayMembers = (members) => {
    if (!members?.length) {
      return '';
    }

    return members.map((member) => (
      <div className="m-2">
        <Badge count="━" style={{ cursor: 'pointer' }} onClick={() => handleRemoveMember(member)}>
          <Card customStyle={{ baseStyle: { margin: '0rem' } }} props={member} />
        </Badge>
      </div>
    ));
  };

  const handleRemoveLeader = (leader) => {
    let newLeaderData = departmentData.leader?.data?.filter(
      (currLeader) => currLeader?.attributes?.name !== leader.name,
    );
    setDepartmentData({
      ...departmentData,
      leaders: { data: newLeaderData },
    });
  };

  const handleDisplayLeaders = (leaders) => {
    if (!leaders?.length) {
      return '';
    }

    return leaders.map((leaders) => (
      <div className="m-2">
        <Badge count="━" style={{ cursor: 'pointer' }} onClick={() => handleRemoveLeader(leaders)}>
          <Card customStyle={{ baseStyle: { margin: '0rem' } }} props={leaders} />
        </Badge>
      </div>
    ));
  };

  const handleProcessPersonData = (data) => {
    if (!data) return;

    return data?.data?.map((person) => {
      const { name, email, birthday } = person.attributes;
      const image = person.attributes?.image || ''; // Assuming image might be missing
      return { name, email, birthday, image };
    });
  };

  const handleSubmit = async ({ id, data }) => {
    try {
      await apiUserPutDepartment({ id, data });
      navigate('/hrMainPage/department');
    } catch (error) {
      console.error('apiUserPutDepartment_ERROR', error);
    }
  };

  const handleEditData = (data, name) => {
    setDepartmentData({
      ...departmentData,
      [name]: data,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let result = await apiUserGetDepartment({ id });
        setDepartmentData(result?.data?.attributes);
      } catch (error) {
        console.error('Error fetching department data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex h-full items-center justify-center p-8">
      <div className="w-[765px] rounded-xl bg-white p-14">
        <>
          <div className="flex justify-end">
            <IconPencil
              className="cursor-pointer"
              size={32}
              onClick={() => navigate(`/hrMainPage/editDepartment/${id}`)}
            />
          </div>
          <div className="flex">
            <div>
              <div className="py-3 text-2xl font-bold" style={{ color: '#6F6F6F' }}>
                部門名稱
              </div>
              <Input
                placeholder="Pick value"
                value={name}
                onChange={(e) => handleEditData(e.target.value, 'name')}
              />
            </div>
            <div className="pl-3">
              <div className="py-3 text-2xl font-bold" style={{ color: '#6F6F6F' }}>
                部門代號
              </div>
              <Input
                placeholder="Pick value"
                value={code}
                onChange={(e) => handleEditData(e.target.value, 'code')}
              />
            </div>
          </div>

          <div className="py-4">
            <div className="py-3 text-2xl font-bold" style={{ color: '#6F6F6F' }}>
              部門描述
            </div>
            <Textarea
              placeholder="Input placeholder"
              value={workContent}
              onChange={(e) => handleEditData(e.target.value, 'workContent')}
            />
          </div>

          <div>
            <div className="text-2xl font-bold" style={{ color: '#6F6F6F' }}>
              部門主管
            </div>
            <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
              {handleDisplayLeaders(handleProcessPersonData(leaders))}
            </div>
          </div>
          <div>
            <div className="text-2xl font-bold" style={{ color: '#6F6F6F' }}>
              部門成員
            </div>
            <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
              {handleDisplayMembers(handleProcessPersonData(members))}
            </div>
          </div>
          <div className="flex">
            <Button
              variant="default"
              style={{ width: '100%', margin: '1rem' }}
              radius="md"
              onClick={() => navigate('/hrMainPage/department/')}
            >
              取消修改
            </Button>

            <Button
              variant="filled"
              color="gray"
              style={{ width: '100%', margin: '1rem' }}
              radius="md"
              onClick={() => {
                let memberSubmitData = members?.data?.length ? members : [];
                let leaderSubmitData = leaders?.data?.length ? leaders : [];
                handleSubmit({
                  id,
                  data: {
                    code,
                    workContent,
                    name,
                    company,
                    leaders: leaderSubmitData,
                    members: memberSubmitData,
                  },
                });
              }}
            >
              確認修改
            </Button>
          </div>
        </>
      </div>
    </div>
  );
}

export default EditDepartmentPage;

import {
  Anchor,
  Box,
  Button,
  Checkbox,
  Divider,
  Group,
  Input,
  Select,
  Textarea,
} from '@mantine/core';
import { IconMail, IconPencil } from '@tabler/icons-react';
import { apiUserGetCompany, apiUserPostCompany } from '@/api/company';
import { useEffect, useState } from 'react';

import { useData } from '@/utility/state/contextAPI';
import { useNavigate } from 'react-router-dom';

function EditCompanyListPage() {
  const [companyData, setCompanyData] = useState(null);

  const navigate = useNavigate();

  const {
    auth: { jwtToken },
  } = useData();

  const {
    fax = '',
    localPhoneNumber = '',
    email = '',
    capitalAmount = '',
    businessItems = '',
    companyRepresentative = '',
    name = '',
    businessIdNumber = '',
    invitationCode = '',
    invoice = '',
    companyAbbreviation = '',
    website = '',
    address = '',
    postalCode = '',
    district = '',
    countyAndCity = '',
    department = '',
    telephone = '',
    logo = '',
    contactPerson = '',
  } = companyData || {};

  const handleSubmitEditCompany = async (id, companyData) => {
    try {
      let result = await apiUserPostCompany({
        id: id,
        data: companyData,
      });

      navigate('/hrMainPage/companyInfo');
    } catch (error) {
      console.error('Error put company data:', error);
    }
  };

  const handleDataChange = (name, value) => {
    setCompanyData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let result = await apiUserGetCompany({ id: localStorage.getItem('companyId') });
        if (result?.data?.attributes) {
          setCompanyData(result?.data?.attributes);
        }
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex h-full items-center justify-center p-14">
      <div className="w-[700px] rounded-xl bg-white px-16">
        <div className="flex w-full justify-between py-8">
          <div className="text-6xl font-bold" style={{ color: '#6F6F6F' }}>
            {name}
          </div>
        </div>
        <div style={{ color: '#6F6F6F' }} className="py-2">
          建立公司資訊，節省您在管理時所花費的時間。建立只需兩分鐘。
        </div>
        <div className="text-2xl font-bold" style={{ color: '#6F6F6F' }}>
          基本資訊
        </div>
        <div className="py-2 flex">
          <div>
            <div style={{ color: '#6F6F6F' }}>公司名稱</div>
            <div className="flex py-2 content-center">
              <Input
                placeholder="Input component"
                leftSection={<IconMail size={16} />}
                value={name}
                onChange={({ target: { value } }) => handleDataChange('name', value)}
              />
            </div>
          </div>
          <div className="pl-12">
            <div style={{ color: '#6F6F6F' }}>統一編號</div>
            <div className="flex py-2 content-center">
              <Input
                placeholder="Input component"
                leftSection={<IconMail size={16} />}
                value={businessIdNumber}
                onChange={({ target: { value } }) => handleDataChange('businessIdNumber', value)}
              />
            </div>
          </div>
        </div>
        <div className="py-2 flex">
          <div className="flex items-center justify-center">
            <div className="py-2">
              <div style={{ color: '#6F6F6F' }}>負責人名稱</div>
              <div className="flex py-2 content-center">
                <Input
                  placeholder="Input component"
                  leftSection={<IconMail size={16} />}
                  value={companyRepresentative}
                  onChange={({ target: { value } }) =>
                    handleDataChange('companyRepresentative', value)
                  }
                />
              </div>
            </div>

            <div className="pl-12">
              <div style={{ color: '#6F6F6F' }}>營業項目</div>
              <div className="flex py-2 content-center">
                <Input
                  placeholder="Input component"
                  leftSection={<IconMail size={16} />}
                  value={businessItems}
                  onChange={({ target: { value } }) => handleDataChange('businessItems', value)}
                />
              </div>
            </div>
            <div className="pl-12">
              <div style={{ color: '#6F6F6F' }}>資本額</div>
              <div className="flex py-2 content-center">
                <Input
                  placeholder="Input component"
                  leftSection={<IconMail size={16} />}
                  value={capitalAmount}
                  onChange={({ target: { value } }) => handleDataChange('capitalAmount', value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="text-2xl font-bold" style={{ color: '#6F6F6F' }}>
          聯絡資訊
        </div>
        <div className="flex items-center justify-start">
          <div className="py-4">
            <div style={{ color: '#6F6F6F' }}>Email</div>
            <div className="flex py-2 align-center">
              <Input
                placeholder="Input component"
                leftSection={<IconMail size={16} />}
                value={email}
                onChange={({ target: { value } }) => handleDataChange('email', value)}
              />
            </div>
          </div>
          <div className="pl-12">
            <div style={{ color: '#6F6F6F' }}>市內電話</div>
            <div className="flex py-2 align-center">
              <Input
                placeholder="Input component"
                leftSection={<IconMail size={16} />}
                value={localPhoneNumber}
                onChange={({ target: { value } }) => handleDataChange('localPhoneNumber', value)}
              />
            </div>
          </div>
          <div className="pl-12">
            <div style={{ color: '#6F6F6F' }}>傳真</div>
            <div className="flex py-2 align-center">
              <Input
                placeholder="Input component"
                leftSection={<IconMail size={16} />}
                value={fax}
                onChange={({ target: { value } }) => handleDataChange('fax', value)}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-start">
          <div className="py-4">
            <div style={{ color: '#6F6F6F' }}>聯絡人</div>
            <div className="flex py-2 align-center">
              <Input
                placeholder="Input component"
                leftSection={<IconMail size={16} />}
                value={contactPerson}
                onChange={({ target: { value } }) => handleDataChange('contactPerson', value)}
              />
            </div>
          </div>

          <div className="pl-12">
            <div style={{ color: '#6F6F6F' }}>電話</div>
            <div className="flex py-2 align-center">
              <Input
                placeholder="Input component"
                leftSection={<IconMail size={16} />}
                value={telephone}
                onChange={({ target: { value } }) => handleDataChange('telephone', value)}
              />
            </div>
          </div>
          <div className="pl-12">
            <div style={{ color: '#6F6F6F' }}>部門</div>
            <div className="flex py-2 align-center">
              <Input
                placeholder="Input component"
                leftSection={<IconMail size={16} />}
                value={department}
                onChange={({ target: { value } }) => handleDataChange('department', value)}
              />
            </div>
          </div>
        </div>

        <div className="text-2xl font-bold" style={{ color: '#6F6F6F' }}>
          聯絡地址
        </div>
        <div className="flex items-center justify-center">
          <div className="py-4">
            <div style={{ color: '#6F6F6F' }}>縣市</div>
            <div className="flex py-2 align-center">
              <Input
                placeholder="Input component"
                leftSection={<IconMail size={16} />}
                value={countyAndCity}
                onChange={({ target: { value } }) => handleDataChange('countyAndCity', value)}
              />
            </div>
          </div>

          <div className="pl-12">
            <div style={{ color: '#6F6F6F' }}>行政區</div>
            <div className="flex py-2 align-center">
              <Input
                placeholder="Input component"
                leftSection={<IconMail size={16} />}
                value={district}
                onChange={({ target: { value } }) => handleDataChange('district', value)}
              />
            </div>
          </div>
          <div className="pl-12">
            <div style={{ color: '#6F6F6F' }}>郵遞區號</div>
            <div className="flex py-2 align-center">
              <Input
                placeholder="Input component"
                leftSection={<IconMail size={16} />}
                value={postalCode}
                onChange={({ target: { value } }) => handleDataChange('postalCode', value)}
              />
            </div>
          </div>
          <div className="pl-12">
            <div style={{ color: '#6F6F6F' }}>地址</div>
            <div className="flex py-2 align-center">
              <Input
                placeholder="Input component"
                leftSection={<IconMail size={16} />}
                value={address}
                onChange={({ target: { value } }) => handleDataChange('address', value)}
              />
            </div>
          </div>
        </div>

        <div className="text-2xl font-bold" style={{ color: '#6F6F6F' }}>
          進階設定
        </div>

        <div className="py-4">
          <div style={{ color: '#6F6F6F' }}>Logo</div>
          <div className="flex items-center justify-start">
            <div className="flex py-2 align-center">
              <Input
                placeholder="Input component"
                leftSection={<IconMail size={16} />}
                value={logo}
                onChange={({ target: { value } }) => handleDataChange('logo', value)}
              />
            </div>
          </div>
        </div>

        <div className="py-4">
          <div style={{ color: '#6F6F6F' }}>網站</div>
          <div className="flex py-2 align-center">
            <Input
              placeholder="Input component"
              leftSection={<IconMail size={16} />}
              value={website}
              onChange={({ target: { value } }) => handleDataChange('website', value)}
            />
          </div>
        </div>
        <div className="flex items-center justify-start">
          <div className="py-4">
            <div style={{ color: '#6F6F6F' }}>公司簡稱</div>
            <div className="flex py-2 align-center">
              <Input
                placeholder="Input component"
                leftSection={<IconMail size={16} />}
                value={companyAbbreviation}
                onChange={({ target: { value } }) => handleDataChange('companyAbbreviation', value)}
              />
            </div>
          </div>
          <div className="pl-12">
            <div style={{ color: '#6F6F6F' }}>發票抬頭</div>
            <div className="flex py-2 align-center">
              <Input
                placeholder="Input component"
                leftSection={<IconMail size={16} />}
                value={invoice}
                onChange={({ target: { value } }) => handleDataChange('invoice', value)}
              />
            </div>
          </div>
        </div>

        <div className="text-2xl font-bold" style={{ color: '#6F6F6F' }}>
          邀請碼
        </div>

        <div className="py-4 pl-2">
          <div style={{ color: '#6F6F6F' }}>輸入邀請碼</div>
          <div className="flex py-2 align-center">
            <Input
              placeholder="Input component"
              leftSection={<IconMail size={16} />}
              value={invitationCode}
              onChange={({ target: { value } }) => handleDataChange('invitationCode', value)}
            />
          </div>
        </div>

        <div className="flex">
          <Button
            variant="default"
            style={{ width: '100%', margin: '1rem' }}
            radius="md"
            onClick={() => navigate(`/hrMainPage/companyInfo`)}
          >
            取消
          </Button>
          <Button
            variant="filled"
            color="gray"
            style={{ width: '100%', margin: '1rem' }}
            radius="md"
            onClick={() => handleSubmitEditCompany(localStorage.getItem('companyId'), companyData)}
          >
            修改完成
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EditCompanyListPage;

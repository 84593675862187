import {
  apiUserChangePassword,
  apiUserForgetPassword,
  apiUserGetJWT,
  apiUserGetMe,
  apiUserRegister,
} from '@/api/auth';
import { apiUserGetCompanies, apiUserGetCompany, apiUserPostCompany } from '@/api/company';
import {
  apiUserGetDepartment,
  apiUserGetDepartments,
  apiUserPutDepartment,
} from '@/api/department';
import { apiUserGetEmployee, apiUserGetEmployees } from '@/api/employee';
import { apiUserGetPartner, apiUserGetPartners, apiUserPostPartner } from '@/api/partner';

import axios from 'axios';

const ApiImageClient = axios.create({
  //VITE_import issue: https://github.com/vitejs/vite/issues/9829
  baseURL: 'https://sfc-beta-dph2f3e9epc2b4bs.japaneast-01.azurewebsites.net/api/',
  timeout: 70000,
});

ApiImageClient.interceptors.request.use(
  (config) => {
    if (!config.skipAuth) {
      //暫時用固定token
      let token = localStorage.getItem('jwtToken');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    // Handle the error
    return Promise.reject(error);
  },
);

export {
  ApiImageClient,
  apiUserPostCompany,
  apiUserGetJWT,
  apiUserRegister,
  apiUserPutDepartment,
  apiUserForgetPassword,
  apiUserChangePassword,
  apiUserGetCompany,
  apiUserGetCompanies,
  apiUserGetDepartment,
  apiUserGetDepartments,
  apiUserGetPartners,
  apiUserGetPartner,
  apiUserPostPartner,
  apiUserGetMe,
  apiUserGetEmployee,
  apiUserGetEmployees,
};

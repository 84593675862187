import '@/index.css';

import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';

import AddCompanyListPage from '@/pages/AddCompanyListPage';
import AddCoopListPage from '@/pages/AddCoopListPage';
import AddDepartmentListPage from '@/pages/AddDepartmentListPage';
import AddEmployeeListPage from '@/pages/AddEmployeeListPage';
import App from '@/App';
import { BaseContextProvider } from '@/utility/state/contextAPI';
import CompanyInfoPage from '@/pages/CompanyInfoPage';
import CompanyListPage from '@/pages/CompanyListPage';
import CoopManagement from '@/pages/CoopManagement';
import DepartmentManagement from '@/pages/DepartmentManagement';
import DepartmentPage from '@/pages/DepartmentPage';
import EditCompanyListPage from '@/pages/EditCompanyListPage';
import EditDepartmentPage from '@/pages/EditDepartmentPage';
import EditEmployeePage from '@/pages/EditEmployeePage';
import EmployeeManagement from '@/pages/EmployeeManagement';
import EmployeePage from '@/pages/EmployeePage';
import HRMainPage from '@/pages/HRMainPage';
import LoginPage from '@/pages/LoginPage';
import { MantineProvider } from '@mantine/core';
import PhoneVerificationPage from '@/pages/PhoneVerificationPage';
import SignUpPage from '@/pages/SignUpPage';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      { path: 'login', element: <LoginPage /> },
      { path: 'phoneVerification', element: <PhoneVerificationPage /> },
      { path: 'signUp', element: <SignUpPage /> },
      { path: 'companyListPage', element: <CompanyListPage /> },
      { path: 'addCompanyListPage', element: <AddCompanyListPage /> },
      {
        path: 'hrMainPage',
        element: <HRMainPage />,
        children: [
          {
            path: 'companyInfo/',
            element: <CompanyInfoPage />,
          },
          {
            path: 'editCompanyInfo',
            element: <EditCompanyListPage />,
          },
          {
            path: 'editEmployee/:id',
            element: <EditEmployeePage />,
          },
          {
            path: 'employee/:id',
            element: <EmployeePage />,
          },
          {
            path: 'employee/',
            element: <EmployeeManagement />,
          },
          {
            path: 'addEmployee/',
            element: <AddEmployeeListPage />,
          },
          {
            path: 'department',
            element: <DepartmentManagement />,
          },
          {
            path: 'department/:id',
            element: <DepartmentPage />,
          },
          {
            path: 'editDepartment/:id',
            element: <EditDepartmentPage />,
          },
          {
            path: 'addDepartment/',
            element: <AddDepartmentListPage />,
          },
          {
            path: 'coop',
            element: <CoopManagement />,
          },
          {
            path: 'addCoop',
            element: <AddCoopListPage />,
          },
        ],
      },
      {
        path: '*',
        element: localStorage.getItem('jwtToken') ? (
          <Navigate to="/companyListPage" />
        ) : (
          <Navigate to="/login" />
        ),
      },
    ],
  },
]);

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <BaseContextProvider>
        <MantineProvider>
          <RouterProvider router={router} />
        </MantineProvider>
      </BaseContextProvider>
    </StrictMode>,
  );
}

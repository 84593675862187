import { Avatar, Title } from '@mantine/core';

const DepartmentCard = ({ props, onClick }) => {
  const { code = '', name = '', workContent = '', company = '', leaders = '' } = props?.attributes;

  // members暫時拿掉
  // const handleDisplayMember = (members) => {
  //   if (members.length) {
  //     return (
  //       <div className="flex">
  //         <Avatar.Group>
  //           {members.slice(0, 3).map((member) => {
  //             return member?.img ? (
  //               <Avatar src={member?.img} />
  //             ) : (
  //               <Avatar key={member?.name} name={member?.name} />
  //             );
  //           })}
  //         </Avatar.Group>
  //         <div className="ml-2 flex items-center">{`${members[0]?.name}等${members.length}人已經加入`}</div>
  //       </div>
  //     );
  //   } else {
  //     return <div style={{ color: '#6F6F6F' }}>尚未有人加入此部門</div>;
  //   }
  // };
  return (
    <div
      className="mx-4 my-4 flex max-w-md cursor-pointer flex-col rounded-lg bg-white p-6 shadow-lg md:mx-8 md:max-w-2xl"
      onClick={onClick}
    >
      <div className="py-2">{code}</div>
      <Title className="py-2" order={1}>
        {name}
      </Title>
      {/* <div className="py-2">{handleDisplayMember(members)}</div> */}
    </div>
  );
};

export default DepartmentCard;

import { IconMail, IconUser } from '@tabler/icons-react';

import { Button } from '@mantine/core';
import { Input } from '@mantine/core';
import SFC_logo from '/Logo/SFC_logo.png';
import { useState } from 'react';

function AddCompanyListPage() {
  const [currentStage, setCurrentStage] = useState(0);

  return (
    <div className="flex h-full items-center justify-center p-14">
      <div className="w-[700px] rounded-xl bg-white px-16">
        {currentStage === 0 ? (
          <>
            <img src={SFC_logo} />
            <div className="text-6xl font-bold" style={{ color: '#6F6F6F' }}>
              新增公司
            </div>
            <div style={{ color: '#6F6F6F' }} className="py-8">
              建立公司資訊，節省您在管理時所花費的時間。建立只需兩分鐘。
            </div>
            <div className="text-2xl font-bold" style={{ color: '#6F6F6F' }}>
              基本資訊
            </div>
            <div className="py-4">
              <div style={{ color: '#6F6F6F' }}>公司資訊</div>
              <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
            </div>
            <div className="py-4">
              <div style={{ color: '#6F6F6F' }}>統一編號</div>
              <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
            </div>
            <div className="flex items-center justify-center">
              <div className="py-4">
                <div style={{ color: '#6F6F6F' }}>負責人名稱</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>

              <div className="py-4 pl-2">
                <div style={{ color: '#6F6F6F' }}>營業項目</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>
              <div className="py-4 pl-2">
                <div style={{ color: '#6F6F6F' }}>資本額</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>
            </div>
            <div className="text-2xl font-bold" style={{ color: '#6F6F6F' }}>
              聯絡資訊
            </div>
            <div className="flex items-center justify-center">
              <div className="py-4">
                <div style={{ color: '#6F6F6F' }}>市內電話</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>

              <div className="py-4 pl-2">
                <div style={{ color: '#6F6F6F' }}>傳真</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>
            </div>
            <div className="flex items-center justify-center">
              <div className="py-4">
                <div style={{ color: '#6F6F6F' }}>聯絡人</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>

              <div className="py-4 pl-2">
                <div style={{ color: '#6F6F6F' }}>電話</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>
              <div className="py-4 pl-2">
                <div style={{ color: '#6F6F6F' }}>部門</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>
            </div>

            <div className="text-2xl font-bold" style={{ color: '#6F6F6F' }}>
              聯絡地址
            </div>
            <div className="flex items-center justify-center">
              <div className="py-4">
                <div style={{ color: '#6F6F6F' }}>縣市</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>

              <div className="py-4 pl-2">
                <div style={{ color: '#6F6F6F' }}>行政區</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>
              <div className="py-4 pl-2">
                <div style={{ color: '#6F6F6F' }}>郵遞區號</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>
              <div className="py-4">
                <div style={{ color: '#6F6F6F' }}>地址</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>
            </div>

            <div className="text-2xl font-bold" style={{ color: '#6F6F6F' }}>
              進階設定
            </div>

            <div className="py-4">
              <div style={{ color: '#6F6F6F' }}>Logo</div>
              <div className="flex items-center justify-center">
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
                <Button
                  variant="filled"
                  color="gray"
                  style={{ minWidth: '3rem', margin: '1rem' }}
                  radius="md"
                  onClick={() => setCurrentStage(1)}
                >
                  選擇圖檔
                </Button>
              </div>
            </div>

            <div className="py-4">
              <div style={{ color: '#6F6F6F' }}>網站</div>
              <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
            </div>
            <div className="flex items-center justify-center">
              <div className="py-4">
                <div style={{ color: '#6F6F6F' }}>公司簡稱</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>
              <div className="py-4 pl-2">
                <div style={{ color: '#6F6F6F' }}>發票抬頭</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>
            </div>

            <div className="text-2xl font-bold" style={{ color: '#6F6F6F' }}>
              邀請碼
            </div>

            <div className="py-4 pl-2">
              <div style={{ color: '#6F6F6F' }}>輸入邀請碼</div>
              <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
            </div>

            <Button
              variant="filled"
              color="gray"
              style={{ width: '100%', margin: '1rem 0' }}
              radius="md"
              onClick={() => setCurrentStage(1)}
            >
              新增
            </Button>
          </>
        ) : (
          <>
            <div style={{ color: '#6F6F6F' }}>新增公司成功！</div>
            <Button
              variant="filled"
              color="gray"
              style={{ width: '100%', margin: '1rem 0' }}
              radius="md"
              onClick={() => setCurrentStage(1)}
            >
              立即開啟
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

export default AddCompanyListPage;

// spinner related
const actionOpenSpinner = 'OPEN_SPINNER';
const actionCloseSpinner = 'CLOSE_SPINNER';

const actionOpenModal = 'OPEN_MODAL';
const actionCloseModal = 'CLOSE_MODAL';
const actionSetModalContent = 'SET_MODAL_CONTENT';

const actionSetJwtToken = 'SET_JWT_TOKEN';

export {
  actionOpenSpinner,
  actionCloseSpinner,
  actionOpenModal,
  actionCloseModal,
  actionSetModalContent,
  actionSetJwtToken,
};

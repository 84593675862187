import { Anchor, Breadcrumbs, Button } from '@mantine/core';
import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';

import Header from '@/components/Header';
import { Outlet } from 'react-router-dom';
import React from 'react';
import SFC_logo from '/Logo/SFC_logo.png';
import routeMap from '../../routeMap';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

const { Sider, Content } = Layout;

const tempData = [
  {
    key: '基本資訊',
    icon: React.createElement(LaptopOutlined),
    label: '基本資訊',
    children: [
      {
        key: '公司資訊',
        label: '公司資訊',
      },
      {
        key: '員工管理',
        label: '員工管理',
      },
      {
        key: '部門管理',
        label: '部門管理',
      },
      {
        key: '訂閱方案',
        label: '訂閱方案',
      },
    ],
  },
  {
    key: '專案管理',
    icon: React.createElement(LaptopOutlined),
    label: '專案管理',
    children: [
      {
        key: 9,
        label: 'option9',
      },
      {
        key: 10,
        label: 'option10',
      },
      {
        key: 11,
        label: 'option11',
      },
      {
        key: 12,
        label: 'option12',
      },
    ],
  },
  {
    key: '財務',
    icon: React.createElement(LaptopOutlined),
    label: '財務',
    children: [
      {
        key: 13,
        label: 'option9',
      },
      {
        key: 14,
        label: 'option10',
      },
      {
        key: 15,
        label: 'option11',
      },
      {
        key: 16,
        label: 'option12',
      },
    ],
  },
  {
    key: '倉儲',
    icon: React.createElement(LaptopOutlined),
    label: '倉儲',
    children: [
      {
        key: 17,
        label: 'option9',
      },
      {
        key: 18,
        label: 'option10',
      },
      {
        key: 19,
        label: 'option11',
      },
      {
        key: 20,
        label: 'option12',
      },
    ],
  },
  {
    key: '採購',
    icon: React.createElement(LaptopOutlined),
    label: '採購',
    children: [
      {
        key: 21,
        label: 'option9',
      },
      {
        key: 22,
        label: 'option10',
      },
      {
        key: 23,
        label: 'option11',
      },
      {
        key: 24,
        label: 'option12',
      },
    ],
  },
  {
    key: '合作方',
    icon: React.createElement(LaptopOutlined),
    label: '合作方',
    children: [
      {
        key: '合作方列表',
        label: '合作方列表',
      },
      {
        key: '審核中',
        label: '審核中',
      },
      {
        key: '已審核',
        label: '已審核',
      },
    ],
  },
];

const HRMainPage: React.FC = () => {
  const [currSteps, setCurrSteps] = useState([]);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();

  const onClick = (e) => {
    const routePath = [];

    let steps = e?.keyPath.map((keys) => {
      // 處理route跳轉
      routePath.push(keys);

      // 處理breadcrumb
      return (
        <div href={keys} key={keys}>
          {keys}
        </div>
      );
    });

    function getRoute(pathArray, obj) {
      let current = obj;

      for (let i = 0; i < pathArray.length; i++) {
        if (current[pathArray[i]]) {
          current = current[pathArray[i]];

          if (i === pathArray.length - 1) {
            return current;
          }
          i = -1;
        }
      }

      return undefined;
    }

    const route = '/' + getRoute(routePath?.reverse(), routeMap);

    setCurrSteps(steps.reverse());
    //routeMap
    navigate(route);
  };

  return (
    <Layout className="h-full min-h-screen">
      <Sider
        className="w-3/10 h-full overflow-auto bg-gray-100"
        style={{ background: colorBgContainer }}
      >
        <img src={SFC_logo} alt="SFC Logo" className="w-full p-4" />
        <Menu
          mode="inline"
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['']}
          style={{ height: '100%' }}
          onClick={(e) => onClick(e)}
          items={tempData}
        />
      </Sider>
      <Layout className="w-7/10 min-h-screen overflow-auto bg-white">
        <Content className="h-full min-h-screen overflow-auto bg-white">
          <Header />
          <div className="p-4" style={{ backgroundColor: '#E4E4E4' }}>
            <Breadcrumbs>{currSteps}</Breadcrumbs>
          </div>
          <div style={{ backgroundColor: '#ECECEC', height: '100%' }}>
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default HRMainPage;

import { Anchor, Avatar, Box, Button, Input, Textarea } from '@mantine/core';
import { IconMail, IconPencil, IconUser } from '@tabler/icons-react';

import employeeTempData from 'employeeTempData';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

function EmployeePage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    name,
    gender,
    birthday,
    bloodType,
    nationalIdNo,
    motorbikeLicense,
    carLicense,
    city,
    district,
    postalCode,
    address,
    email,
    img,
  } = employeeTempData[0];

  return (
    <div className="flex h-full items-center justify-center p-14">
      <div className="w-[765px] rounded-xl bg-white p-16">
        <div className="flex justify-end">
          <IconPencil
            className="cursor-pointer"
            size={32}
            onClick={() => navigate(`/hrMainPage/editEmployee/${id}`)}
          />
        </div>
        <div className="flex">
          {img ? <img src={img} /> : <Avatar size="xl" key={name} name={name[0]} />}
          <div className="p-4">
            <div className="text-3xl font-bold" style={{ color: '#6F6F6F' }}>
              {name}
            </div>
            <div className="" style={{ color: '#6F6F6F' }}>
              {gender}
            </div>
          </div>
        </div>
        <div className="py-4 text-2xl font-bold" style={{ color: '#6F6F6F' }}>
          基本資訊
        </div>
        <div className="flex justify-between py-4">
          <div className="py-4">
            <div style={{ color: '#6F6F6F' }}>生日</div>
            <div className="flex">
              <IconMail size={16} />
              <div className="pl-2"> {birthday}</div>
            </div>
          </div>
          <div className="py-4">
            <div style={{ color: '#6F6F6F' }}>血型</div>
            <div className="flex">
              <IconMail size={16} />
              <div className="pl-2">{bloodType}型</div>
            </div>
          </div>
          <div className="py-4">
            <div style={{ color: '#6F6F6F' }}>身分證</div>
            <div className="flex">
              <IconMail size={16} />
              <div className="pl-2">{nationalIdNo}</div>
            </div>
          </div>
        </div>

        <div className="py-4">
          <div style={{ color: '#6F6F6F' }}>駕照</div>
          <div className="flex py-4">
            {motorbikeLicense ? (
              <div className="flex px-4">
                <IconMail size={16} />
                <div className="pl-2">{motorbikeLicense}</div>
              </div>
            ) : (
              ''
            )}
            {carLicense ? (
              <div className="flex px-4">
                <IconMail size={16} />
                <div className="pl-2">{carLicense}</div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="py-4 text-2xl font-bold" style={{ color: '#6F6F6F' }}>
          聯絡地址
        </div>
        <div className="flex justify-between py-4">
          <div className="py-4">
            <div style={{ color: '#6F6F6F' }}>縣市</div>
            <div className="flex">
              <IconMail size={16} />
              <div className="pl-2">{city}</div>
            </div>
          </div>

          <div className="py-4">
            <div style={{ color: '#6F6F6F' }}>行政區</div>
            <div className="flex">
              <IconMail size={16} />
              <div className="pl-2">{district}</div>
            </div>
          </div>

          <div className="py-4">
            <div style={{ color: '#6F6F6F' }}>郵遞區號</div>
            <div className="flex">
              <IconMail size={16} />
              <div className="pl-2">{postalCode}</div>
            </div>
          </div>

          <div className="py-4">
            <div style={{ color: '#6F6F6F' }}>地址</div>
            <div className="flex">
              <IconMail size={16} />
              <div className="pl-2">{address}</div>
            </div>
          </div>
        </div>

        <div className="py-4 text-2xl font-bold" style={{ color: '#6F6F6F' }}>
          聯絡資訊
        </div>
        <div className="flex justify-start py-4">
          <div className="py-4">
            <div style={{ color: '#6F6F6F' }}>Email / 帳號</div>
            <div className="flex">
              <IconMail size={16} />
              <div className="pl-2">{email}</div>
            </div>
          </div>

          <div className="py-4 pl-4">
            <div style={{ color: '#6F6F6F' }}>聯絡電話</div>
            <div className="flex">
              <IconMail size={16} />
              <div className="pl-2">{}</div>
            </div>
          </div>
        </div>
        <div className="flex justify-between py-4">
          <div className="py-4">
            <div style={{ color: '#6F6F6F' }}>緊急聯絡人</div>
            <div className="flex">
              <IconMail size={16} />
              <div className="pl-2">{city}</div>
            </div>
          </div>

          <div className="py-4 pl-4">
            <div style={{ color: '#6F6F6F' }}>關係</div>
            <div className="flex">
              <IconMail size={16} />
              <div className="pl-2">{city}</div>
            </div>
          </div>

          <div className="py-4 pl-4">
            <div style={{ color: '#6F6F6F' }}>緊急聯絡人電話</div>
            <div className="flex">
              <IconMail size={16} />
              <div className="pl-2">{city}</div>
            </div>
          </div>
        </div>
        <div className="py-4">
          <div className="py-4 text-2xl font-bold" style={{ color: '#6F6F6F' }}>
            備註
          </div>
          <Textarea placeholder="Input placeholder" />
        </div>
      </div>
    </div>
  );
}

export default EmployeePage;

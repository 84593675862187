import '@mantine/core/styles.css';

import { Button, Modal } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import Loader from '@/components/Loader';
import { Outlet } from 'react-router-dom';
import { apiUserGetJWT } from '@/api/auth';
import { useData } from '@/utility/state/contextAPI';
import { useEffect } from 'react';

function App() {
  const {
    auth: { contextSetJwtToken, jwtToken },
    modal: { isSpinnerModalOpen, modalContent, isModalOpen },
  } = useData();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const title = typeof modalContent === 'object' ? modalContent?.title : '';
  const content = typeof modalContent === 'object' ? modalContent?.content : modalContent;

  useEffect(() => {
    const localJwtToken = localStorage.getItem('jwtToken');
    const localCompanyId = localStorage.getItem('companyId');

    //  沒登入的情況
    if (!jwtToken) {
      if (localJwtToken) {
        contextSetJwtToken(localJwtToken);
      } else if (!['/login', '/signUp'].includes(pathname)) {
        navigate(`/login`);
        return;
      }
    }

    // 沒有選擇company身份
    if (!localCompanyId && !['/companyListPage'].includes(pathname)) {
      if (!['/login', '/signUp'].includes(pathname)) {
        navigate(`/companyListPage`);
        return;
      }
    }

    // 有登入＋有選擇company身份
    if (['/login', '/signUp', '/'].includes(pathname)) {
      navigate(`/companytListPage`);
    }
  }, []);

  return (
    <div className="App min-h-screen bg-[#CCCCCC]">
      <Loader isLoading={isSpinnerModalOpen} />
      <Modal
        open={isModalOpen}
        {...(typeof modalContent === 'string' ? { content: modalContent } : modalContent)}
        title={title}
      >
        {content}
      </Modal>
      <Outlet />
    </div>
  );
}

export default App;

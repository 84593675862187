import React, { Dispatch, ReactNode, createContext, useContext, useReducer } from 'react';
import {
  actionCloseModal,
  actionCloseSpinner,
  actionOpenModal,
  actionOpenSpinner,
  actionSetJwtToken,
  actionSetModalContent,
} from './actions';

import { baseReducer } from './reducer';

interface Action {
  type: string;
  payload?: any;
}

interface ModalState {
  isSpinnerModalOpen: boolean;
  isModalOpen: boolean;
  modalContent: string | { title: string; content: React.ReactNode };
}

interface AuthState {
  jwtToken: string;
}

interface BaseState {
  auth: AuthState;
  modal: ModalState;
}

const initialBaseData: BaseState = {
  auth: {
    jwtToken: '',
  },
  modal: { isSpinnerModalOpen: false, isModalOpen: false, modalContent: '' },
};

interface BaseContextProps {
  auth: {
    jwtToken: string;
    contextSetJwtToken: (token: string) => void;
  };
  modal: {
    modalContent: string | { title: string; content: React.ReactNode };
    isSpinnerModalOpen: boolean;
    isModalOpen: boolean;
    contextOpenSpinner: () => void;
    contextCloseSpinner: () => void;
    contextOpenModal: () => void;
    contextCloseModal: () => void;
    contextSetModalContent: (content: string) => void;
  };
}

export const useData = (): BaseContextProps => {
  const context = useContext(BaseContext);
  if (context === undefined) {
    throw new Error('Something wrong with baseContext');
  }

  return context;
};

export const BaseContext = createContext<BaseContextProps | undefined>(undefined);
export const BaseContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer<React.Reducer<BaseState, Action>>(
    baseReducer,
    initialBaseData,
  );

  const contextOpenSpinner = () => {
    dispatch({
      type: actionOpenSpinner,
    });
  };
  const contextCloseSpinner = () => {
    dispatch({
      type: actionCloseSpinner,
    });
  };
  const contextOpenModal = () => {
    dispatch({
      type: actionOpenModal,
    });
  };
  const contextCloseModal = () => {
    dispatch({
      type: actionCloseModal,
    });
  };
  const contextSetModalContent = (content: string) => {
    dispatch({
      type: actionSetModalContent,
      payload: content,
    });
  };

  const contextSetJwtToken = (token: string) => {
    dispatch({
      type: actionSetJwtToken,
      payload: token,
    });
  };

  const value: BaseContextProps = {
    auth: {
      jwtToken: state.auth.jwtToken,
      contextSetJwtToken,
    },
    modal: {
      modalContent: state.modal.modalContent,
      isSpinnerModalOpen: state.modal.isSpinnerModalOpen,
      isModalOpen: state.modal.isModalOpen,
      contextOpenSpinner,
      contextCloseSpinner,
      contextOpenModal,
      contextCloseModal,
      contextSetModalContent,
    },
  };
  return <BaseContext.Provider value={value}>{children}</BaseContext.Provider>;
};

import { ApiImageClient } from '@/api';

async function apiUserGetDepartment({ id }: { id: string }) {
  try {
    const result = await ApiImageClient.get(`/departments/${id}?populate=*`).then(
      ({ data }) => data,
    );
    return result;
  } catch (e) {
    console.error('apiUserGetDepartment_Error', e);
  }
}

async function apiUserGetDepartments() {
  try {
    const result = await ApiImageClient.get(`/departments/`).then(({ data }) => data);
    return result;
  } catch (e) {
    console.error('apiUserGetDepartments_Error', e);
  }
}

async function apiUserPutDepartment({ id, data }) {
  try {
    const result = await ApiImageClient.put(`/departments/${id}`, { data }).then(
      ({ data }) => data,
    );
    return result;
  } catch (e) {
    console.error('apiUserPutDepartment_Error', e);
  }
}

export { apiUserGetDepartment, apiUserGetDepartments, apiUserPutDepartment };

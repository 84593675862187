export default {
  基本資訊: {
    公司資訊: 'hrMainPage/companyInfo',
    員工管理: 'hrMainPage/employee',
    部門管理: 'hrMainPage/department',
    訂閱方案: 'hrMainPage/subscribe',
  },
  合作方: {
    合作方列表: 'hrMainPage/coop',
    審核中: 'hrMainPage/coopPending',
    已審核: 'hrMainPage/coopReviewed',
  },
};

import { Anchor, Box, Button, Input, Textarea } from '@mantine/core';
import { IconMail, IconUser } from '@tabler/icons-react';

import { useData } from '@/utility/state/contextAPI';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

function AddEmployeeListPage() {
  const [currentStage, setCurrentStage] = useState(0);
  const {
    modal: { contextOpenModal, contextCloseModal, contextSetModalContent },
  } = useData();
  const navigate = useNavigate();
  const { modal } = useData();

  const handleCancelButton = () => {
    contextOpenModal();
    contextSetModalContent({
      title: '您確定要取消新增員工嗎？',
      onCancel: contextCloseModal,
      onOk: () => {
        contextCloseModal();
        navigate(`/hrMainPage/employee`);
      },
    });
  };

  return (
    <div className="flex h-full items-center justify-center p-8">
      <div className="w-[765px] rounded-xl bg-white p-14">
        <>
          <div className="py-3 text-6xl font-bold" style={{ color: '#6F6F6F' }}>
            新增員工
          </div>
          <div className="py-3 text-2xl font-bold" style={{ color: '#6F6F6F' }}>
            基本資訊
          </div>
          <div className="py-4">
            <div style={{ color: '#6F6F6F' }}>姓名</div>
            <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
          </div>
          <div className="py-4">
            <div style={{ color: '#6F6F6F' }}>生理性別</div>
            <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
          </div>
          <div className="flex items-center justify-start">
            <div className="py-4">
              <div style={{ color: '#6F6F6F' }}>生日</div>
              <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
            </div>

            <div className="py-4 pl-2">
              <div style={{ color: '#6F6F6F' }}>血型</div>
              <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
            </div>
            <div className="py-4 pl-2">
              <div style={{ color: '#6F6F6F' }}>身分證</div>
              <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
            </div>
          </div>
          <div className="flex items-center justify-start">
            <div className="py-4">
              <div style={{ color: '#6F6F6F' }}>駕照</div>
              <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
            </div>

            <div className="py-4 pl-2">
              <div style={{ color: '#6F6F6F' }}>機車</div>
              <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
            </div>
            <div className="py-4 pl-2">
              <div style={{ color: '#6F6F6F' }}>汽車</div>
              <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
            </div>
          </div>

          <div className="py-4 text-2xl font-bold" style={{ color: '#6F6F6F' }}>
            聯絡地址
          </div>
          <div className="flex items-center justify-start">
            <div className="py-4">
              <div style={{ color: '#6F6F6F' }}>縣市</div>
              <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
            </div>

            <div className="py-4 pl-2">
              <div style={{ color: '#6F6F6F' }}>行政區</div>
              <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
            </div>
            <div className="py-4 pl-2">
              <div style={{ color: '#6F6F6F' }}>郵遞區號</div>
              <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
            </div>
            <div className="py-4 pl-2">
              <div style={{ color: '#6F6F6F' }}>地址</div>
              <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
            </div>
          </div>

          <div className="py-4 text-2xl font-bold" style={{ color: '#6F6F6F' }}>
            聯絡資訊
          </div>
          <div className="flex items-center justify-start">
            <div className="py-4">
              <div style={{ color: '#6F6F6F' }}>Email / 帳號</div>
              <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
            </div>

            <div className="py-4 pl-2">
              <div style={{ color: '#6F6F6F' }}>緊急連絡人</div>
              <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
            </div>
          </div>
          <div className="flex items-center justify-start">
            <div className="py-4">
              <div style={{ color: '#6F6F6F' }}>關係</div>
              <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
            </div>

            <div className="py-4 pl-2">
              <div style={{ color: '#6F6F6F' }}>緊急聯絡人電話</div>
              <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
            </div>
          </div>

          <div className="py-4">
            <div className="text-2xl font-bold" style={{ color: '#6F6F6F' }}>
              備註
            </div>
            <Textarea placeholder="Input placeholder" />
          </div>

          <div className="flex">
            <Button
              variant="default"
              style={{ width: '100%', margin: '1rem' }}
              radius="md"
              onClick={() => handleCancelButton()}
            >
              取消
            </Button>

            <Button
              variant="filled"
              color="gray"
              style={{ width: '100%', margin: '1rem' }}
              radius="md"
              onClick={() => setCurrentStage(1)}
            >
              新增
            </Button>
          </div>
        </>
      </div>
    </div>
  );
}
export default AddEmployeeListPage;

import { ApiImageClient } from '@/api';

async function apiUserGetJWT({ identifier, password }: { identifier: string; password: string }) {
  try {
    const result = await ApiImageClient.post(
      '/auth/local',
      {
        identifier,
        password,
      },
      { skipAuth: true },
    )
      .then(({ data }) => data)
      .then(({ jwt }) => jwt);
    return result;
  } catch (e) {
    console.error('apiUserGetJWT_ERROR', e);
  }
}

async function apiUserRegister({
  username,
  email,
  password,
}: {
  username: string;
  email: string;
  password: string;
}) {
  try {
    const result = await ApiImageClient.post(
      '/auth/local/register',
      {
        username,
        email,
        password,
      },
      { skipAuth: true },
    ).then(({ jwt }) => jwt);
    return result;
  } catch (e) {
    console.error('apiUserRegister', e);
  }
}

async function apiUserForgetPassword({ email }: { email: string }) {
  try {
    await ApiImageClient.post('/auth/forgetPassword', {
      email,
    });
  } catch (e) {
    console.error('apiUserRegister', e);
  }
}

async function apiUserChangePassword({
  password,
  currPassword,
  passwordConfirmation,
}: {
  password: string;
  currPassword: string;
  passwordConfirmation: string;
}) {
  try {
    return await ApiImageClient.post('/auth/changePassword', {
      password,
      currPassword,
      passwordConfirmation,
    }).then(({ jwt }) => jwt);
  } catch (e) {
    console.error('apiUserRegister', e);
  }
}

async function apiUserGetMe() {
  try {
    const result = await ApiImageClient.get('/users/me').then(({ data }) => data);
    return result;
  } catch (e) {
    console.error('apiUserGetMe_ERROR', e);
  }
}

export {
  apiUserGetJWT,
  apiUserRegister,
  apiUserForgetPassword,
  apiUserChangePassword,
  apiUserGetMe,
};

import { ApiImageClient } from '@/api';

async function apiUserGetPartner({ id }: { id: string }) {
  try {
    const result = await ApiImageClient.get(`/partners/${id}`).then(({ data }) => data);
    return result;
  } catch (e) {
    console.error('apiUserGetPartner_Error', e);
  }
}

async function apiUserGetPartners() {
  try {
    const result = await ApiImageClient.get(`/partners/`).then(({ data }) => data);
    return result;
  } catch (e) {
    console.error('apiUserGetPartners_Error', e);
  }
}

async function apiUserPostPartner({ props }: { props: any }) {
  try {
    const result = await ApiImageClient.post('/partners', { data: props }).then(({ data }) => data);
    return result;
  } catch (e) {
    console.error('apiUserPostPartner_Error', e);
  }
}

export { apiUserGetPartner, apiUserGetPartners, apiUserPostPartner };

import { Anchor, Box, Button, Input, Textarea } from '@mantine/core';
import { Badge, Select } from 'antd';
import { IconMail, IconSearch, IconUser } from '@tabler/icons-react';

import Card from '@/components/Card';
import tempData from '../../employeeTempData';
import { useData } from '@/utility/state/contextAPI';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

function AddDepartmentListPage() {
  const [currentStage, setCurrentStage] = useState(0);
  const [departmentData, setDepartmentData] = useState([]);
  const {
    modal: { contextOpenModal, contextCloseModal, contextSetModalContent },
  } = useData();
  const navigate = useNavigate();

  const handleRemoveMember = (member) => {
    let newMemberData = departmentData.members.filter(
      (currMember) => currMember.name !== member.name,
    );
    setDepartmentData({
      ...departmentData,
      members: newMemberData,
    });
  };

  const handleDisplayMembers = (members) => {
    if (!members.length) {
      return '';
    }

    return members.map((member) => (
      <div className="m-2">
        <Badge count="━" style={{ cursor: 'pointer' }} onClick={() => handleRemoveMember(member)}>
          <Card customStyle={{ baseStyle: { margin: '0rem' } }} props={member} />
        </Badge>
      </div>
    ));
  };

  return currentStage === 0 ? (
    <div className="flex h-full items-center justify-center p-8">
      <div className="w-[765px] rounded-xl bg-white p-14">
        <>
          <div className="py-3 text-6xl font-bold" style={{ color: '#6F6F6F' }}>
            新增部門
          </div>
          <div className="flex">
            <div>
              <div className="py-3 text-2xl font-bold" style={{ color: '#6F6F6F' }}>
                部門名稱
              </div>
              <Input placeholder="Pick value" />
            </div>
            <div className="pl-3">
              <div className="py-3 text-2xl font-bold" style={{ color: '#6F6F6F' }}>
                部門代號
              </div>
              <Input placeholder="Pick value" />
            </div>
          </div>

          <div className="py-4">
            <div className="py-3 text-2xl font-bold" style={{ color: '#6F6F6F' }}>
              部門描述
            </div>
            <Textarea placeholder="Input placeholder" />
          </div>

          <div className="py-8">
            <div className="text-2xl font-bold" style={{ color: '#6F6F6F' }}>
              部門主管
            </div>
            <div className="flex">
              <Input placeholder="輸入主管姓名" leftSection={<IconSearch size={16} />} />

              <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
                {handleDisplayMembers(departmentData)}
              </div>
            </div>
          </div>
          <div className="py-8">
            <div className="text-2xl font-bold" style={{ color: '#6F6F6F' }}>
              部門成員
            </div>
            <div className="flex">
              <Input placeholder="輸入成員姓名" leftSection={<IconSearch size={16} />} />

              <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
                {handleDisplayMembers(departmentData)}
              </div>
            </div>
          </div>
          <div className="flex">
            <Button
              variant="default"
              style={{ width: '100%', margin: '1rem' }}
              radius="md"
              onClick={() => navigate('/hrMainPage/department')}
            >
              取消
            </Button>
            <Button
              variant="filled"
              color="gray"
              style={{ width: '100%', margin: '1rem' }}
              radius="md"
              onClick={() => setCurrentStage(1)}
            >
              新增
            </Button>
          </div>
        </>
      </div>
    </div>
  ) : (
    <div className="flex h-full items-center justify-center p-14 text-center">
      <div className="w-[487px] rounded-xl bg-white p-8">
        <div style={{ color: '#6F6F6F' }}>建立部門成功！</div>
        <Button
          variant="filled"
          color="gray"
          style={{ width: '100%', margin: '1rem 0' }}
          radius="md"
          onClick={() => navigate('/hrMainPage/department')}
        >
          確定
        </Button>
      </div>
    </div>
  );
}
export default AddDepartmentListPage;

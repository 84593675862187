import { IconBellFilled, IconLogout, IconUserFilled } from '@tabler/icons-react';
import { useEffect, useState } from 'react';

import { apiUserGetMe } from '@/api';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const [userData, setUserData] = useState([]);

  const navigate = useNavigate();
  const {
    id = '',
    username = '',
    email = '',
    provider = '',
    confirmed = '',
    blocked = '',
  } = userData || {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiUserGetMe();
        setUserData(response);
      } catch (error) {
        console.error('Error_apiUserGetMe:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <header className="flex h-20 items-center justify-between bg-white px-8">
      <div className="flex w-full flex-row items-center justify-end">
        <div className="ml-4 flex flex-row items-center cursor-pointer">
          <div
            onClick={() => {
              navigate(`/companyListPage`);
            }}
            className="mr-1 text-lg text-gray-800"
          >
            回到公司列表
          </div>
          <IconLogout />
        </div>
        <div className="ml-4 flex flex-row items-center cursor-pointer">
          <div
            onClick={() => {
              window.localStorage.clear();
              navigate(`/login`);
            }}
            className="mr-1 text-lg text-gray-800"
          >
            登出
          </div>
          <IconLogout />
        </div>
        <div className="ml-4 flex flex-row items-center">
          <div className="text-lg text-gray-800">{username}</div>
          <IconUserFilled />
        </div>
      </div>
    </header>
  );
};

export default Header;

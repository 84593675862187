import {
  actionCloseModal,
  actionCloseSpinner,
  actionOpenModal,
  actionOpenSpinner,
  actionSetJwtToken,
  actionSetModalContent,
} from './actions';

export const baseReducer = (state, action) => {
  switch (action.type) {
    case actionOpenSpinner: {
      return {
        ...state,
        modal: {
          ...state.modal,
          isSpinnerModalOpen: true,
        },
      };
    }

    case actionCloseSpinner: {
      return {
        ...state,
        modal: {
          ...state.modal,
          isSpinnerModalOpen: false,
        },
      };
    }

    case actionOpenModal: {
      return {
        ...state,
        modal: {
          ...state.modal,
          isModalOpen: true,
        },
      };
    }

    case actionCloseModal: {
      return {
        ...state,
        modal: {
          ...state.modal,
          isModalOpen: false,
        },
      };
    }

    case actionSetModalContent: {
      return {
        ...state,
        modal: {
          ...state.modal,
          modalContent: action.payload,
        },
      };
    }

    case actionSetJwtToken: {
      localStorage.setItem('jwtToken', action.payload);

      return {
        ...state,
        auth: {
          ...state.auth,
          jwtToken: action.payload,
        },
      };
    }
  }
};

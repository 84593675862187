import { Avatar } from '@mantine/core';

const Card = ({ props, onClick, customStyle }) => {
  const { image = '', name = '', email = '', birthday = '' } = props?.attributes || {};
  return (
    <div
      className="mx-4 my-4 flex max-w-md cursor-pointer flex-col rounded-lg bg-white p-3 shadow-lg md:mx-8 md:max-w-2xl md:flex-row"
      onClick={onClick}
      style={customStyle?.baseStyle}
    >
      <div className="md:flex-3 relative flex w-full items-center justify-center">
        {image ? <img src={image} /> : <Avatar size="xl" key={name} name={name[0]} />}
      </div>
      <div className="md:flex-7 w-full px-3 py-4">
        <div className="text-xl font-semibold text-black">{name}</div>
        <p className="mt-1 text-gray-600">{email}</p>
        <p className="mt-1 text-gray-600">{birthday}</p>d
      </div>
    </div>
  );
};

export default Card;

import { Button, TextInput } from '@mantine/core';
import { useEffect, useState } from 'react';

import Card from '@/components/Card';
import { apiUserGetEmployees } from '@/api';
import tempData from '../../employeeTempData';
import { useNavigate } from 'react-router-dom';

function EmployeeManagement() {
  const [filteredData, setFilteredData] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    const filtered = tempData.filter((item) => item.name.includes(value));
    setFilteredData(filtered);
  };
  const handleClickEmployeeCard = (data) => {
    navigate(`/hrMainPage/employee/${data.ID}`);
  };

  const handleAddEmployee = () => {
    navigate('/hrMainPage/addEmployee');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let result = await apiUserGetEmployees();
        setFilteredData(result?.data);
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    };

    fetchData();
  }, []);

  return filteredData?.length === 0 ? (
    <div className="flex h-full items-center justify-center">
      <div className="w-[487px] rounded-xl bg-white px-16 py-20">
        <div className="text-center" style={{ color: '#6F6F6F' }}>
          您尚未新增員工資料！你可以試試：
        </div>
        <div className="flex text-center">
          <Button
            variant="outline"
            color="gray"
            style={{ width: '100%', margin: '1rem' }}
            radius="md"
          >
            範本Excel
          </Button>
          <Button
            variant="outline"
            color="gray"
            style={{ width: '100%', margin: '1rem' }}
            radius="md"
            onClick={handleAddEmployee}
          >
            手動新增
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="flex items-center justify-between p-4" style={{ backgroundColor: '#ECECEC' }}>
        <div className="flex flex-1 justify-center">
          <TextInput value={inputValue} onChange={handleInputChange} />
        </div>
        <Button
          variant="default"
          style={{ minWidth: '3rem', margin: '1rem' }}
          radius="xl"
          onClick={() => handleAddEmployee()}
        >
          新增員工
        </Button>
      </div>

      <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
        {filteredData.map((data) => {
          return <Card props={data} onClick={() => handleClickEmployeeCard(data)} />;
        })}
      </div>
    </>
  );
}

export default EmployeeManagement;

import { Avatar, Box, Button, Input, Textarea, em } from '@mantine/core';
import { IconMail, IconUser } from '@tabler/icons-react';

import tempEmployeeData from '../../employeeTempData';
import { useData } from '@/utility/state/contextAPI';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

function EditEmployeePage() {
  const [currentStage, setCurrentStage] = useState(0);
  const [employeeData, setEmployeeData] = useState(tempEmployeeData[0]);
  const {
    modal: { contextOpenModal, contextCloseModal, contextSetModalContent },
  } = useData();
  const navigate = useNavigate();
  const { modal } = useData();

  const handleCancelButton = () => {
    contextOpenModal();
    contextSetModalContent({
      title: '您確定要取消編輯員工嗎？',
      onCancel: contextCloseModal,
      onOk: () => {
        contextCloseModal();
        navigate(`/hrMainPage/employee`);
      },
    });
  };

  console.log('employeeData', employeeData);

  const handleChangeEmployeeData = (value, type) => {
    setEmployeeData({
      ...employeeData,
      [type]: value,
    });
  };

  const {
    name,
    gender,
    birthday,
    bloodType,
    ID,
    motorbikeLicense,
    carLicense,
    city,
    district,
    postalCode,
    address,
    email,
    img,
  } = employeeData;

  return (
    <div className="flex h-full items-center justify-center p-8">
      <div className="w-[765px] rounded-xl bg-white p-14">
        <>
          <div className="flex justify-center">
            {img ? <img src={img} /> : <Avatar size="xl" key={name} name={name[0]} />}
          </div>
          <div className="py-3 text-2xl font-bold" style={{ color: '#6F6F6F' }}>
            基本資訊
          </div>
          <div className="py-4">
            <div style={{ color: '#6F6F6F' }}>姓名</div>
            <Input
              value={name}
              onChange={(e) => handleChangeEmployeeData(e.target.value, 'name')}
              placeholder="Input component"
              leftSection={<IconMail size={16} />}
            />
          </div>
          <div className="py-4">
            <div style={{ color: '#6F6F6F' }}>生理性別</div>
            <Input
              value={gender}
              onChange={(e) => handleChangeEmployeeData(e.target.value, 'gender')}
              placeholder="Input component"
              leftSection={<IconMail size={16} />}
            />
          </div>
          <div className="flex items-center justify-start">
            <div className="py-4">
              <div style={{ color: '#6F6F6F' }}>生日</div>
              <Input
                value={birthday}
                placeholder="Input component"
                onChange={(e) => handleChangeEmployeeData(e.target.value, 'birthday')}
                leftSection={<IconMail size={16} />}
              />
            </div>

            <div className="py-4 pl-2">
              <div style={{ color: '#6F6F6F' }}>血型</div>
              <Input
                value={bloodType}
                placeholder="Input component"
                onChange={(e) => handleChangeEmployeeData(e.target.value, 'bloodType')}
                leftSection={<IconMail size={16} />}
              />
            </div>
            <div className="py-4 pl-2">
              <div style={{ color: '#6F6F6F' }}>身分證</div>
              <Input
                value={ID}
                placeholder="Input component"
                onChange={(e) => handleChangeEmployeeData(e.target.value, 'ID')}
                leftSection={<IconMail size={16} />}
              />
            </div>
          </div>
          <div className="flex items-center justify-start">
            <div className="py-4">
              <div style={{ color: '#6F6F6F' }}>駕照</div>
              {/* <Input  placeholder="Input component" leftSection={<IconMail size={16} />} /> */}
            </div>

            <div className="py-4 pl-2">
              <div style={{ color: '#6F6F6F' }}>機車</div>
              <Input
                value={motorbikeLicense}
                placeholder="Input component"
                onChange={(e) => handleChangeEmployeeData(e.target.value, 'motorbikeLicense')}
                leftSection={<IconMail size={16} />}
              />
            </div>
            <div className="py-4 pl-2">
              <div style={{ color: '#6F6F6F' }}>汽車</div>
              <Input
                value={carLicense}
                placeholder="Input component"
                onChange={(e) => handleChangeEmployeeData(e.target.value, 'carLicense')}
                leftSection={<IconMail size={16} />}
              />
            </div>
          </div>

          <div className="py-4 text-2xl font-bold" style={{ color: '#6F6F6F' }}>
            聯絡地址
          </div>
          <div className="flex items-center justify-center">
            <div className="py-4">
              <div style={{ color: '#6F6F6F' }}>縣市</div>
              <Input
                value={city}
                placeholder="Input component"
                onChange={(e) => handleChangeEmployeeData(e.target.value, 'city')}
                leftSection={<IconMail size={16} />}
              />
            </div>

            <div className="py-4 pl-2">
              <div style={{ color: '#6F6F6F' }}>行政區</div>
              <Input
                value={district}
                onChange={(e) => handleChangeEmployeeData(e.target.value, 'district')}
                placeholder="Input component"
                leftSection={<IconMail size={16} />}
              />
            </div>
            <div className="py-4 pl-2">
              <div style={{ color: '#6F6F6F' }}>郵遞區號</div>
              <Input
                value={postalCode}
                onChange={(e) => handleChangeEmployeeData(e.target.value, 'postalCode')}
                placeholder="Input component"
                leftSection={<IconMail size={16} />}
              />
            </div>
            <div className="py-4 pl-2">
              <div style={{ color: '#6F6F6F' }}>地址</div>
              <Input
                value={address}
                placeholder="Input component"
                onChange={(e) => handleChangeEmployeeData(e.target.value, 'address')}
                leftSection={<IconMail size={16} />}
              />
            </div>
          </div>

          <div className="py-4 text-2xl font-bold" style={{ color: '#6F6F6F' }}>
            聯絡資訊
          </div>

          <div style={{ color: '#6F6F6F' }}>Email / 帳號</div>
          <Input
            value={email}
            placeholder="Input component"
            onChange={(e) => handleChangeEmployeeData(e.target.value, 'email')}
            leftSection={<IconMail size={16} />}
          />
          <div className="flex items-center justify-start py-4">
            <div>
              <div style={{ color: '#6F6F6F' }}>緊急連絡人</div>
              <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
            </div>

            <div className="flex items-center justify-center">
              <div className="pl-2">
                <div style={{ color: '#6F6F6F' }}>關係</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>

              <div className="pl-2">
                <div style={{ color: '#6F6F6F' }}>緊急聯絡人電話</div>
                <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              </div>
            </div>
          </div>

          <div className="py-4">
            <div className="text-2xl font-bold" style={{ color: '#6F6F6F' }}>
              備註
            </div>
            <Textarea placeholder="Input placeholder" />
          </div>

          <div className="flex">
            <Button
              variant="default"
              style={{ width: '100%', margin: '1rem' }}
              radius="md"
              onClick={() => handleCancelButton()}
            >
              取消
            </Button>

            <Button
              variant="filled"
              color="gray"
              style={{ width: '100%', margin: '1rem' }}
              radius="md"
              onClick={() => setCurrentStage(1)}
            >
              修改完成
            </Button>
          </div>
        </>
      </div>
    </div>
  );
}
export default EditEmployeePage;

import {
  Anchor,
  Box,
  Button,
  Checkbox,
  Divider,
  Group,
  Input,
  Select,
  Textarea,
} from '@mantine/core';
import { IconMail, IconUser } from '@tabler/icons-react';

import SFC_logo from '/Logo/SFC_logo.png';
import { useState } from 'react';

function PhoneVerificationPage() {
  const [phoneVerificationStage, setPhoneVerificationStage] = useState(0);
  return (
    <div className="flex h-full items-center justify-center">
      <div className="w-[487px] rounded-xl bg-white px-16 py-20">
        {false ? (
          <>
            <img src={SFC_logo} />
            <div style={{ color: '#6F6F6F' }}>
              看來你還沒有新增任何公司！開始新增你的第一間公司吧！
            </div>
            <Button
              variant="filled"
              color="gray"
              style={{ width: '100%', margin: '1rem' }}
              radius="md"
            >
              開始
            </Button>
            <Button
              variant="outline"
              color="gray"
              style={{ width: '100%', margin: '1rem' }}
              radius="md"
            >
              略過，直接開始
            </Button>
            <div className="flex items-center justify-center p-[1rem]">
              <Checkbox label="不再提醒" />
            </div>
          </>
        ) : (
          <>
            <img src={SFC_logo} />
            <div style={{ color: '#6F6F6F' }}>您尚未進行手機驗證！</div>
            <div className="py-4">
              <div style={{ color: '#6F6F6F' }}>已經傳送簡訊驗證至 xxxxxxxx：</div>
              <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
              <div className="py-4 text-center">五分鍾後重新發送簡訊</div>
            </div>
            <Button
              variant="filled"
              color="gray"
              style={{ width: '100%', margin: '1rem' }}
              radius="md"
            >
              確定
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

export default PhoneVerificationPage;

import { Anchor, Box, Button, Checkbox, Divider, Group, Select, Textarea } from '@mantine/core';
import { IconMail, IconUser } from '@tabler/icons-react';

import { Input } from '@mantine/core';
import SFC_logo from '/Logo/SFC_logo.png';
import { apiUserRegister } from '@/api/auth';
import { useState } from 'react';

function SignUpPage() {
  const [currentStage, setCurrentStage] = useState(0);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = async (password, confirmPassword, email) => {
    if (password === confirmPassword && password && confirmPassword && email) {
      try {
        let result = await apiUserRegister({
          username: email,
          password,
          email,
        });
        if (result) {
          setCurrentStage(1);
        } else {
        }
      } catch (error) {
        console.error('Error fetching JWT:', error);
      }
    }
  };
  const handleOnChange = (value, changeState) => {
    changeState(value);
  };

  return (
    <div className="flex h-screen items-center justify-center">
      <div className="w-[487px] rounded-xl bg-white px-16">
        {currentStage === 0 ? (
          <>
            <img src={SFC_logo} />
            <div className="text-6xl font-bold" style={{ color: '#6F6F6F' }}>
              註冊
            </div>
            <div className="py-4">
              <Button variant="filled" color="gray" style={{ width: '100%' }} radius="md">
                快速註冊
              </Button>
            </div>
            <Divider my="xs" label="or" labelPosition="center" />
            <div className="py-4">
              <div style={{ color: '#6F6F6F' }}>Email</div>
              <Input
                value={email}
                onChange={(e) => handleOnChange(e.target.value, setEmail)}
                placeholder="Input component"
                leftSection={<IconMail size={16} />}
              />
            </div>
            <div className="py-4">
              <div style={{ color: '#6F6F6F' }}>設定密碼</div>
              <Input
                value={password}
                onChange={(e) => handleOnChange(e.target.value, setPassword)}
                placeholder="請輸入六位數字組合"
                leftSection={<IconUser size={16} />}
              />
            </div>
            <div className="py-4">
              <div style={{ color: '#6F6F6F' }}>確認密碼</div>
              <Input
                value={confirmPassword}
                onChange={(e) => handleOnChange(e.target.value, setConfirmPassword)}
                placeholder="請輸入六位數字組合"
                leftSection={<IconUser size={16} />}
              />
            </div>
            <Button
              variant="filled"
              color="gray"
              style={{ width: '100%', margin: '1rem 0' }}
              radius="md"
              //onClick={() => setCurrentStage(1)}
              onClick={() => handleSubmit(password, confirmPassword, email)}
            >
              註冊
            </Button>
            <div className="flex items-center justify-center p-[1rem]">已有帳號？ 立即註冊</div>
          </>
        ) : (
          <>
            <div className="py-4">
              <div style={{ color: '#6F6F6F' }}>姓名＊</div>
              <Input placeholder="Input component" leftSection={<IconMail size={16} />} />
            </div>
            <div className="py-4">
              <div style={{ color: '#6F6F6F' }}>手機號碼</div>
              <Input placeholder="請輸入六位數字組合" leftSection={<IconUser size={16} />} />
            </div>
            <div className="py-4">
              <div style={{ color: '#6F6F6F' }}>國家及地區＊</div>
              {/* <Input placeholder="請輸入六位數字組合" leftSection={<IconUser size={16} />} /> */}
              <Select placeholder="Pick value" data={['React', 'Angular', 'Vue', 'Svelte']} />
            </div>
            <div className="py-4">
              <div style={{ color: '#6F6F6F' }}>個人簡介</div>
              {/* <Input placeholder="請輸入六位數字組合" leftSection={<IconUser size={16} />} /> */}
              <Textarea placeholder="Input placeholder" />
            </div>
            <div className="py-4">
              <div style={{ color: '#6F6F6F' }}>從哪個管道得知廣達ERP管道：</div>
              <div className="flex items-center justify-center p-[1rem]">
                <Group mt="xs" style={{ color: '#6F6F6F' }}>
                  <Checkbox value="react" label="公司要求" />
                  <Checkbox value="svelte" label="親友推薦" />
                  <Checkbox value="ng" label="網絡" />
                  <Checkbox value="vue" label="展覽/博覽會" />
                  <Checkbox value="vue" label="信件" />
                  <Checkbox value="vue" label="代理商/經銷商" />
                </Group>
              </div>
            </div>

            <Button
              variant="filled"
              color="gray"
              style={{ width: '100%', margin: '1rem 0' }}
              radius="md"
              onClick={() => setCurrentStage(1)}
            >
              確定
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

export default SignUpPage;

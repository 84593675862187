import { Anchor, Box, Button, Checkbox, Divider } from '@mantine/core';
import { IconMail, IconUser } from '@tabler/icons-react';
import { useEffect, useState } from 'react';

import { Input } from '@mantine/core';
import { apiUserGetCompanies } from '@/api';
import { apiUserGetJWT } from '@/api/auth';
import { useData } from '@/utility/state/contextAPI';
import { useNavigate } from 'react-router-dom';

function LoginPage() {
  const [account, setAccount] = useState('');
  const [password, setPassword] = useState('');
  const [isChecked, setIsChecked] = useState(true);

  const navigate = useNavigate();

  const {
    auth: { contextSetJwtToken, jwtToken },
  } = useData();

  const handleOnChange = (value, changeState) => {
    changeState(value);
  };

  const handleOnSubmit = async (account, password, isChecked) => {
    if (account && password) {
      try {
        // identifier: 'foobar',
        // password: 'Test1234',
        let result = await apiUserGetJWT({
          identifier: account,
          password: password,
        });

        if (!result) {
          return console.error('apiUserGetJWT_loginError', result);
        }

        if (isChecked) {
          localStorage.setItem('rememberAccount', JSON.stringify({ account, password }));
        } else {
          localStorage.removeItem('rememberAccount');
        }

        contextSetJwtToken(result);

        let { data: companyList } = await apiUserGetCompanies(result);

        if (companyList && companyList.length > 0) {
          navigate(`/companyListPage`);
        } else {
          navigate(`/addCompanyListPage`);
        }
      } catch (error) {
        console.error('Error fetching JWT:', error);
      }
    }
  };

  useEffect(() => {
    const rememberAccount = JSON.parse(localStorage.getItem('rememberAccount'));

    if (rememberAccount?.account && rememberAccount?.password) {
      setAccount(rememberAccount?.account);
      setPassword(rememberAccount?.password);
    }
  }, []);

  return (
    <div className="flex h-screen items-center justify-center">
      <div className="w-[487px] rounded-xl bg-white px-16 py-20">
        <div className="text-6xl font-bold" style={{ color: '#6F6F6F' }}>
          登入
        </div>
        <div className="py-4">
          <Button variant="filled" color="gray" style={{ width: '100%' }} radius="md">
            快速註冊
          </Button>
        </div>
        <Divider my="xs" label="or" labelPosition="center" />
        <div className="py-4">
          <div style={{ color: '#6F6F6F' }}>Email</div>
          <Input
            onChange={(e) => handleOnChange(e.target.value, setAccount)}
            value={account}
            placeholder="Input component"
            leftSection={<IconMail size={16} />}
          />
        </div>
        <div className="py-4">
          <div style={{ color: '#6F6F6F' }}>設定密碼</div>
          <Input
            onChange={(e) => handleOnChange(e.target.value, setPassword)}
            value={password}
            placeholder="請輸入六位數字組合"
            leftSection={<IconUser size={16} />}
          />
        </div>
        <Button
          onClick={() => handleOnSubmit(account, password, isChecked)}
          variant="filled"
          color="gray"
          style={{ width: '100%' }}
          radius="md"
        >
          登入
        </Button>
        <div className="flex items-center justify-center p-[1rem]">
          <Checkbox
            label="記住帳號"
            onClick={(e) => setIsChecked(e.currentTarget.checked)}
            checked={isChecked}
          />
        </div>
        <div className="flex items-center justify-center p-[1rem]">沒有帳號？ 立即註冊</div>
      </div>
    </div>
  );
}

export default LoginPage;

import { Loader as LoadComponent } from '@mantine/core';

function Loader({ isLoading }) {
  return isLoading ? (
    <div className="flex h-full items-center justify-center">
      <div className="flex w-[487px] flex-col items-center justify-center rounded-xl bg-white px-16 py-20">
        <LoadComponent color="blue" />
        <div className="p-4">歡迎使用 GD-ERP</div>
      </div>
    </div>
  ) : null;
}

export default Loader;

import { Avatar, Button } from '@mantine/core';
import { useEffect, useState } from 'react';

import { IconPlus } from '@tabler/icons-react';
import { apiUserGetCompanies } from '@/api';
import { useNavigate } from 'react-router-dom';

function CompanyListPage() {
  const [companyData, setCompanyData] = useState([]);
  const navigate = useNavigate();
  const handleOnCompanyClicked = (id) => {
    localStorage.setItem('companyId', id);
    navigate(`/hrMainPage/companyInfo`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiUserGetCompanies();
        setCompanyData(response?.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex h-screen items-center justify-center">
      <div className="rounded-xl bg-white px-8 py-8" style={{ minHeight: '700px' }}>
        <div className="text-5xl font-bold" style={{ color: '#6F6F6F' }}>
          公司列表
        </div>
        <div className="py-4 text-base" style={{ color: '#6F6F6F' }}>
          選擇你要開始或是檢視的公司：
        </div>
        <div className="flex justify-between py-4">
          <div>
            <Button variant="filled" color="gray" style={{ margin: '0.75rem' }}>
              最近開啟
            </Button>
            <Button variant="filled" color="gray" style={{ margin: '0.75rem' }}>
              你的公司
            </Button>
          </div>
          <Button
            leftSection={<IconPlus size={14} />}
            variant="outline"
            color="black"
            radius="xl"
            style={{ margin: '0.75rem' }}
            onClick={() => navigate(`/addCompanyListPage`)}
          >
            你的公司
          </Button>
        </div>
        <div>
          <div className="grid grid-cols-2 gap-8">
            {companyData.map((t) => {
              const {
                address,
                business,
                businessIdNumber,
                capitalAmount,
                city,
                company,
                companyAbbreviation,
                companyRepresentative,
                contact,
                department,
                district,
                email,
                fax,
                invitationCode,
                invoice,
                localPhoneNumber,
                postalCode,
                telephone,
                website,
              } = t?.attributes;

              return (
                <div
                  className="overflow-hidden rounded border-2 border-gray-400 p-1 shadow cursor-pointer"
                  style={{ backgroundColor: '#ECECEC' }}
                  onClick={() => handleOnCompanyClicked(t?.id)}
                >
                  <div className="grid grid-cols-10">
                    <div className="col-span-3">
                      {/* Api暫時沒有圖片，所以先這樣顯示 */}
                      {false ? (
                        <img
                          src="your-image-url"
                          alt="companyImage"
                          className="h-full w-full object-cover"
                        />
                      ) : (
                        //<Avatar size="xl" key={company} name={company[0]} />
                        <Avatar size="xl" key={company} name={'test'} />
                      )}
                    </div>
                    <div className="col-span-7 flex flex-col justify-between p-4">
                      <div className="text-left">
                        <p>{company}</p>
                      </div>
                      <div className="">
                        <p>統編 {businessIdNumber}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyListPage;
